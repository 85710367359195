import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ResellerLogo extends Component {
  render() {
    const reseller = localStorage.getItem("latestReseller")

    if (reseller === "PCS") {
      return (
        <Link to={'/'} className="logo-link">
          <img src={"/pcs-logo.png"} className="pcs-logo" alt="Person Centred Software logo"/>
        </Link>
      )
    }
    else if (reseller === "Cool Care") {
      return (
        <Link to={'/'} className="logo-link">
          <img src={"/cool-care-logo.png"} className="cc-logo" alt="Cool Care logo"/>
        </Link>
      )
    }
    else {
      return (
        <Link to={'/'} className="logo-link">
          <img src={"/logo192.png"} className="dr-logo" alt="Digital Reception logo"/>
        </Link>
      )
    }
  }
}

export default ResellerLogo;
