import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { capitalize, adminUser, managerUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'

class CreateTag extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    person_ids: [],
    partial: "",

    page: 1,
    people: [],

    nextDefaultColorLoaded: false,

    peopleLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var person_ids = this.state.person_ids

    if (value === true) {
      if (!person_ids.includes(name)) {
        person_ids.push(name)
      }
    }
    else if (value === false) {
      if (person_ids.includes(name)) {
        let index = person_ids.indexOf(name)
        person_ids.splice(index, 1)
      }
    }

    this.setState({person_ids: person_ids});
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      page: 1
    });

    this.debouncedHandlePersonSearch(value, 1)
  }

  handleNextPage(event) {
    let page = parseInt(this.state.page) + 1

    this.setState(prevState => ({
      page: page,
      people: [],
      peopleLoaded: false
    }))

    this.handlePersonSearch(this.state.partial, page)

    event.preventDefault();
  }

  handlePreviousPage(event) {
    let page = parseInt(this.state.page) - 1

    this.setState(prevState => ({
      page: page,
      people: [],
      peopleLoaded: false
    }))

    this.handlePersonSearch(this.state.partial, page)

    event.preventDefault();
  }

  handlePersonSearch(partial, page) {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?person[partial]=${partial}&page=${page}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ people: data, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, peopleLoaded: true }))
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "tag": {
        "name": this.state.name,
        "person_ids": this.state.person_ids,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderPageButtons(page) {
    if (this.state.people.length < 250 && page === 1) {
      return ""
    }
    else {
      return (
        <div className="page-button-container">
          <button className="button new-button" onClick={this.handlePreviousPage} disabled={parseInt(page) < 2}>Previous Page</button>
          <button className="button new-button" onClick={this.handleNextPage} disabled={this.state.people.length <= 249}>Next Page</button>
        </div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { name, person_ids, partial, people, peopleLoaded, id, page, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/configuration/tags/${id}`}/>
    }

    return (
      <div>
        <SetTitle title={"New Tag | Tags"} />

        <form onSubmit={this.handleSubmit}>
          <div className="settings big-settings">
            <div className="row">
              <HelpText page={'tags'} section={'name'} />

              <label className="column">Tag Name:</label>{ renderErrors(errors, 'name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>
          </div>

          <div className="settings big-settings">
            <div className="row">
              <HelpText page={'tags'} section={'people'} />

              <label className="column">Assign People:</label>{ renderErrors(errors, 'person') }

              <div className="scroll-box">
                <div className="search-box">
                  <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={partial} />

                  <div className="scrollable-table">
                    <table className="checkbox-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Existing Tag</th>
                          <th>Assigned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {people.map((person) => (
                          <tr key={person.id}>
                            <td>
                              <Link to={`/people/${person.id}`}>{person.full_name}</Link>
                            </td>
                            <td>
                              {capitalize(person.type_of_person)}
                            </td>
                            <td>
                              {person.tags.map((tag) => (
                                <Link to={`/configuration/tags/${tag.id}`} key={tag.id}>{tag.name}<br></br></Link>
                              ))}
                            </td>
                            <td className="center-cell">
                              <input className="column" type="checkbox" data-name={person.name} name={person.id} checked={person_ids.includes(person.id)} onChange={this.handleMultiCheckbox} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {peopleLoaded === false ? (
                      <div className="long-tr white-bg">
                        <div className="long-td">
                          Loading...
                        </div>
                      </div>
                    ) : (null)}
                  </div>

                  {this.renderPageButtons(page)}
                </div>
              </div>
            </div>
          </div>

          <div className="settings big-settings">
            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </div>
        </form>
      </div>
    )
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?page=1`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ people: data, searchedPeople: data, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default CreateTag;
