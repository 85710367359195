import React from "react";

const ProgressBar = ({page, service, staff, slot, setPage}) => {
  const handleClick = (event) => {
    const targetValue = event.target.getAttribute('target')
    setPage(targetValue);
  };

  const renderService = (service) => {
    if (service === null) {
      return null
    }

    return (
      <div className="progress-section">
        Service: {service.name} - {service.default_price}
        <button className="button small-button" target="service" onClick={handleClick}>Change</button>
      </div>
    )
  }

  const renderStaff = (staff) => {
    if (staff === null) {
      return null
    }

    return (
      <div className="progress-section">
        Staff: {staff.name}
        <button className="button small-button" target="staff" onClick={handleClick}>Change</button>
      </div>
    )
  }

  const renderSlot = (slot) => {
    if (slot === null) {
      return null
    }

    return (
      <div className="progress-section">
        Slot: {slot.time}
        <button className="button small-button" target="slot" onClick={handleClick}>Change</button>
      </div>
    )
  }

  const renderSections = () => {
    switch(page) {
      case "staff":
        return renderService(service)
      case "slot":
        return (
          <>
            {renderService(service)}
            {renderStaff(staff)}
          </>
        )
      case "confirm":
      case "deposit":
        return (
          <>
            {renderService(service)}
            {renderStaff(staff)}
            {renderSlot(slot)}
          </>
        )
      default:
        return null
    }
  }

  if (page === "service") {
    return null
  }
  else {
    return (
      <>
        <h3 className="selected-options">Selected Options</h3>
        <div className="booking-progress">
          {renderSections()}
        </div>
      </>
    )
  }
}

export default ProgressBar;
