import React, { useState, useEffect } from 'react';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const DepositForm = ({ organisation_id, location_id, service_id, planned_event_id }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretLoaded, setClientSecretLoaded] = useState(false);
  const [stripePromise] = useState(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {}));
  const [error, setError] = useState(null);

  useEffect(() => {
    let json = JSON.stringify({
      booking: {
        organisation_id: organisation_id,
        location_id: location_id,
        service_id: service_id,
        planned_event_id: planned_event_id
      }
    })

    // Fetch the client_secret key from Stripe
    request('POST', "/v1/b/deposit", json, () => { })
      .then(data => {
        setClientSecret(data.client_secret)
        setClientSecretLoaded(true)
      })
      .catch(data => {
        reportErrorToHoneybadger(data.errors)

        setError(data.errors.join(", "))
      })
  }, [organisation_id, location_id, service_id, planned_event_id]);

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  if (clientSecretLoaded === false) {
    return <p>Loading ...</p>;
  }

  const options = {
    clientSecret: clientSecret
  }

  return (
    <div className="deposit-form center">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default DepositForm;
