import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';

import { adminUser, capitalize, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LocationSettings extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleNestedChange = this.handleNestedChange.bind(this);
    this.handleAddDate = this.handleAddDate.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleRemoveDate = this.handleRemoveDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    locations: [],
    selected_location: "",

    open_hours: {},
    special_hours: {},
    booking_form: false,
    booking_form_urls: {},

    loaded: false,
    locationsLoaded: false,
    locationLoading: null,
    locationLoaded: false,
    updating: false,

    open_hours_created: null,
    service_created: null,
    service_assigned_staff: null,
    bookingFormCheckLoaded: false,

    unauthorized: "",
    error: "",
    errors: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    if (name === "selected_location") {
      this.fetchData(value)
      this.bookingFormCheck(value)
    }
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleNestedChange(event) {
    const { name, value, type, checked, dataset } = event.target
    const { day, state } = dataset

    this.setState(prevState => ({
      [state]: {
        ...(prevState[state] || {}),
        [day]: {
          ...(prevState[state]?.[day] || {}),
          [name]: type === "checkbox" ? checked : value
        }
      }
    }));
  }

  handleAddDate(event) {
    this.setState((prevState) => ({
      special_hours: {
        ...prevState.special_hours,
        "placeholder": { start: null, finish: null, open: false },
      },
    }));

    event.preventDefault();
  }

  handleChangeDate(event) {
    const value = event.target.value
    const date = event.target.dataset.date

    if (Object.keys(this.state.special_hours).includes(value)) {
      // Date already exists. Setting it again would replace the current values
      return
    }

    this.setState((prevState) => {
      const newSpecialHours = { ...prevState.special_hours }

      newSpecialHours[value] = newSpecialHours[date]
      delete newSpecialHours[date]

      return { ...prevState, special_hours: newSpecialHours }
    })

    event.preventDefault()
  }

  handleRemoveDate(event) {
    const date = event.target.dataset.date
    
    this.setState((prevState) => {
      const newState = { ...prevState }

      delete newState.special_hours[date]

      return newState
    });

    event.preventDefault();
  }

  handleSubmit(event) {
    this.setState({
      updating: true
    })

    const json = JSON.stringify({
      "location_settings": {
        "open_hours": this.state.open_hours,
        "special_hours": this.state.special_hours,
        "booking_form": this.state.booking_form
      }
    })

    request('PUT', `/v1/a/location_settings/${this.state.selected_location}`, json, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          updating: false,
          errors: "",
        })

        this.bookingFormCheck(this.state.selected_location)

        sessionStorage.setItem("updateSuccess", `Location Settings updated successfully!`);
        this.props.updatePopupCallback(Math.random())
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ updating: false, error: error })
        }
        else {
          this.setState({ updating: false, errors: error })
        }
      })

    event.preventDefault();
  }

  fetchData(selected_location) {
    this.setState({locationLoading: true})

    request('GET', `/v1/a/location_settings/${selected_location}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          open_hours: data.open_hours,
          special_hours: data.special_hours,
          booking_form: data.booking_form,
          booking_form_urls: data.booking_form_urls,
          locationLoaded: true,
          locationLoading: false
        })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ locationLoaded: true, error: error })
        }
        else {
          this.setState({ locationLoaded: true, errors: error })
        }
      })
  }

  bookingFormCheck(selected_location) {
    request('GET', `/v1/a/location_settings/${selected_location}/booking_form_check`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          open_hours_created: data.open_hours_created,
          service_created: data.service_created,
          service_assigned_staff: data.service_assigned_staff,
          bookingFormCheckLoaded: true
        })
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          this.setState({ bookingFormCheckLoaded: true, error: error })
        }
        else {
          this.setState({ bookingFormCheckLoaded: true, errors: error })
        }
      })
  }

  renderOpenHoursInputs(day) {
    const data = this.state.open_hours === null ? {} : this.state.open_hours[day]

    const start = data?.start || ""
    const finish = data?.finish || ""
    const open = data?.open || false

    return (
      <tr key={day}>
        <td>
          {capitalize(day)}
        </td>
        <td className="center-cell">
          <input className="column" type="checkbox" name="open" data-day={day} data-state="open_hours" checked={open} onChange={this.handleNestedChange} />
        </td>
        <td className="center-cell">
          <input className="column" type="time" name="start" data-day={day} data-state="open_hours" value={start} disabled={open === false} onChange={this.handleNestedChange} />
        </td>
        <td className="center-cell">
          <input className="column" type="time" name="finish" data-day={day} data-state="open_hours" value={finish} disabled={open === false} onChange={this.handleNestedChange} />
        </td>
      </tr>
    )
  }

  renderSpecialHoursInputs(date) {
    const data = this.state.special_hours === null ? {} : this.state.special_hours[date]

    const start = data?.start || ""
    const finish = data?.finish || ""
    const open = data?.open || false

    return (
      <tr key={date}>
        <td className="center-cell">
          <input className="column" type="date" name="key" data-date={date} value={date} onChange={this.handleChangeDate} />
        </td>
        <td className="center-cell">
          <input className="column" type="checkbox" name="open" data-day={date} data-state="special_hours" checked={open} onChange={this.handleNestedChange} />
        </td>
        <td className="center-cell">
          <input className="column" type="time" name="start" data-day={date} data-state="special_hours" value={start} disabled={open === false} onChange={this.handleNestedChange} />
        </td>
        <td className="center-cell">
          <input className="column" type="time" name="finish" data-day={date} data-state="special_hours" value={finish} disabled={open === false} onChange={this.handleNestedChange} />
        </td>
        <td className="center-cell">
          <div data-date={date} className="remove-button" onClick={this.handleRemoveDate}>Remove <FontAwesomeIcon icon={faTimes} /></div>
        </td>
      </tr>
    )
  }

  renderBookingFormWarnings() {
    const { open_hours_created, service_created, service_assigned_staff, booking_form, bookingFormCheckLoaded } = this.state;

    if (booking_form === false || bookingFormCheckLoaded === false) {
      return null
    }

    if (open_hours_created === false || service_created === false || service_assigned_staff === false) {
      return (
        <NoticeBox type="info">
          <div>Your booking form has been enabled, but:
            <ul>
              {open_hours_created === false && <li>You haven't set Open Hours yet. Add them below.</li>}
              {service_created === false && <li>You haven't created any Services yet. Create some <Link target="_blank" to={"/configuration/services"}>here</Link>.</li>}
              {service_assigned_staff === false && <li>Your Services do not have Staff from this Location assigned to them. Please do so <Link target="_blank" to={"/configuration/services"}>here</Link>.</li>}
            </ul>
          </div>
        </NoticeBox>
      )
    }
  }

  renderBookingForm() {
    const { booking_form, booking_form_urls, selected_location, locationLoading, locationLoaded, errors } = this.state;

    if (locationLoading === true) {
      return <p>Loading ...</p>
    }

    if (locationLoaded === true && selected_location) {
      return (
        <>
          <div className="row">
            <HelpText page={'location_settings'} section={'booking_form'} />

            <label className="column">Booking Form:</label>{ renderErrors(errors, 'booking_form') }
          </div>

          <div className="row padding-bottom">
            <input className="column small-bottom-margin" type="checkbox" name="booking_form" id="booking_form" checked={booking_form} onChange={this.handleCheckbox} />
            <label className="column checkbox-label" htmlFor="booking_form">Enable Booking Form?</label>
          </div>

          {booking_form === true && (
            <div className="row">
              <p>Your booking form has been enabled. Please direct your customers to this URL, which will be hosted on our website:</p>

              <input className="read-only" type="text" value={booking_form_urls.direct} readOnly />

              <p>Or, you can use the following code to embed it on your website:</p>

              <textarea className="read-only" rows="1" readOnly value={`<iframe src="${booking_form_urls.embedded}" width="1000" height="800"></iframe>`} />
            </div>
          )}
        </>
      )
    }
  }

  renderOpenHours() {
    const { selected_location, locationLoaded, errors } = this.state;

    if (locationLoaded === true && selected_location) {
      return (
        <div className="row">
          <HelpText page={'location_settings'} section={'open_hours'} />

          <label className="column">Open Hours:</label>{ renderErrors(errors, 'open_hours') }

          <table className="checkbox-table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Open?</th>
                <th>Opening Time</th>
                <th>Closing Time</th>
              </tr>
            </thead>
            <tbody>
              {this.renderOpenHoursInputs("monday")}
              {this.renderOpenHoursInputs("tuesday")}
              {this.renderOpenHoursInputs("wednesday")}
              {this.renderOpenHoursInputs("thursday")}
              {this.renderOpenHoursInputs("friday")}
              {this.renderOpenHoursInputs("saturday")}
              {this.renderOpenHoursInputs("sunday")}
            </tbody>
          </table>
        </div>
      )
    }
  }

  renderSpecialHours() {
    const { special_hours, selected_location, locationLoaded, errors } = this.state;

    const length = special_hours ? Object.keys(special_hours).length : 0

    if (locationLoaded === true && selected_location) {
      return (
        <div className="row">
          <HelpText page={'location_settings'} section={'special_hours'} />

          <label className="column">Special Hours:</label>{ renderErrors(errors, 'special_hours') }
          <table className="checkbox-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Open?</th>
                <th>Opening Time</th>
                <th>Closing Time</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {this.state.special_hours && Object.keys(this.state.special_hours).sort((a, b) => new Date(a) - new Date(b)).map((key) => (
                this.renderSpecialHoursInputs(key)
              ))}
            </tbody>
          </table>

          <button className="button small centered" onClick={this.handleAddDate}>Add {length === 0 ? "a" : "another"} date +</button>
        </div>
      )
    }
  }

  renderSaveButton() {
    if (this.state.locationLoaded === false) {
      return null
    }

    if (adminUser()) {
      return <> <input type="submit" value="Save" /> {this.state.updating === true && "Saving..."} </>
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }


  render() {
    const { selected_location, locations, locationsLoaded, unauthorized, errors, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded) {
      return (
        <form className="settings big-settings top-padding" onSubmit={this.handleSubmit}>
          <div className="row">
            <label className="column">Select a Location:</label>
            <select name="selected_location" onChange={this.handleChange} value={selected_location}>
              <option value="">Select</option>
              {locations.map((location) => (
                <option value={location.id} key={location.id}>{location.name}</option>
              ))}
            </select>
          </div>

          {this.renderBookingFormWarnings()}
          {this.renderBookingForm()}
          {this.renderOpenHours()}
          {this.renderSpecialHours()}

          <div className="row">
            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </div>
        </form>
      )
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default LocationSettings;
