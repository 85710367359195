import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import HelpText from '../../components/help/HelpText';

import { spaceAndCapitalize, renderErrors } from '../../utilities/Forms.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class AlertProcessing extends Component {
  static ANSWER_TYPES = [
    "yes",
    "no",
    "one_star",
    "two_stars",
    "three_stars",
    "four_stars",
    "five_stars",
    "very_unhappy",
    "unhappy",
    "neutral",
    "happy",
    "very_happy",
    "two_thumbs_down",
    "one_thumb_down",
    "no_thumbs_up",
    "one_thumb_up",
    "two_thumbs_up",
    "text"
  ];

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleRemoveEmail = this.handleRemoveEmail.bind(this);
    this.handleAddEmail = this.handleAddEmail.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeInvalidEmails = this.removeInvalidEmails.bind(this);
    this.initializeData = this.initializeData.bind(this);
  }

  state = {
    invalidEmails: [],

    tabIndex: 0,

    alertsLoaded: false,
    locationsLoaded: false,

    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    const [bucket, answer_type, attribute] = name.split('|');

    this.setState(prevState => {
      let state = JSON.parse(JSON.stringify(prevState)) // deep copy
      state[bucket][answer_type][attribute] = value
      return state
    })
  }

  handleTabChange(index) {
    this.setState({ tabIndex: index });
  }

  handleEmailChange(event) {
    let name = event.target.name;
    let index = event.target.id;
    let value = event.target.value;

    const [bucket, answer_type, attribute] = name.split('|');

    this.setState(prevState => {
      let state = JSON.parse(JSON.stringify(prevState)) // deep copy

      let newArray = state[bucket][answer_type][attribute]
      newArray[index] = value

      let emailsValid = this.validateAlertEmails(newArray)

      let newInvalidEmailAlerts = state.invalidEmails
      if (emailsValid) {
        newInvalidEmailAlerts = newInvalidEmailAlerts.filter(email => email !== `${bucket}|${answer_type}`)
      }
      else if (newInvalidEmailAlerts.includes(`${bucket}|${answer_type}`) === false) {
        newInvalidEmailAlerts.push(`${bucket}|${answer_type}`)
      }

      this.props.setEmailsValid(newInvalidEmailAlerts)

      state[bucket][answer_type][attribute] = newArray
      state["invalidEmails"] = newInvalidEmailAlerts

      return state
    })
  }

  handleAddEmail(event) {
    const name = event.target.dataset.name;
    const [bucket, answer_type, attribute] = name.split('|');

    this.setState(prevState => {
      let state = JSON.parse(JSON.stringify(prevState))

      state[bucket][answer_type][attribute] = state[bucket][answer_type][attribute].concat([""])

      return state
    })
  }

  handleRemoveEmail(event) {
    let name = event.target.dataset.name;
    let index = event.target.id;

    const [bucket, answer_type, attribute] = name.split('|');

    this.setState(prevState => {
      let state = JSON.parse(JSON.stringify(prevState)) // deep copy

      let newArray = state[bucket][answer_type][attribute]
      newArray.splice(index, 1)

      let emailsValid = this.validateAlertEmails(newArray)

      let newInvalidEmailAlerts = state.invalidEmails
      if (emailsValid) {
        newInvalidEmailAlerts = newInvalidEmailAlerts.filter(email => email !== `${bucket}|${answer_type}`)
      }
      else if (newInvalidEmailAlerts.includes(`${bucket}|${answer_type}`) === false) {
        newInvalidEmailAlerts.push(`${bucket}|${answer_type}`)
      }

      this.props.setEmailsValid(newInvalidEmailAlerts)

      state[bucket][answer_type][attribute] = newArray
      state["invalidEmails"] = newInvalidEmailAlerts

      return state
    })
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    const [bucket, answer_type, attribute] = name.split('|');

    this.setState(prevState => {
      let state = JSON.parse(JSON.stringify(prevState)) // deep copy

      state[bucket][answer_type][attribute] = value
      return state
    })
  }

  handleSubmit() {
    if (this.state.alertsLoaded === false) {
      const verb = this.props.origin === "create" ? "created" : "updated"
      sessionStorage.setItem("updateSuccess", `Feedback Question ${verb} successfully! (Alerts were skipped)`);
      this.props.closePanel()
      return
    }

    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    let json = {
      "feedback_question": {
        "feedback_answer_alerts_attributes": []
      }
    }

    json = this.compileJSON(null, json);

    this.state.locations.map((location) => (
      json = this.compileJSON(location.id, json)
    ))

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(json),
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/${id}/set_feedback_alerts`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
        this.props.setErrors(data)
      } else {
        this.setState({ updated: true, errors: "" })

        const verb = this.props.origin === "create" ? "created" : "updated"
        sessionStorage.setItem("updateSuccess", `Feedback Question ${verb} successfully!`);
        this.props.closePanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))
  }

  compileJSON(location_id, json) {
    let alerts

    if (location_id === null) {
      alerts = this.state.all_locations
    }
    else {
      alerts = this.state[`location_${location_id}`]
    }

    AlertProcessing.ANSWER_TYPES.forEach((answer_type) => {
      const alert = alerts[answer_type]

      // "_destroy" marks a record for deletion by evaluating to true if an ID is present but an email address is not present and the alert email and action needed flag are disabled.
      const _destroy = (
        alert.email.join("").trim() === ""
        && alert.enabled === false
        && alert.action_needed === false
      )

      let answer_json = {
        "answer_value": answer_type,
        "id": alert.id,
        "email": alert.email.join(";"),
        "enabled": alert.enabled,
        "action_needed": alert.action_needed,
        "location_id": location_id,
        "_destroy": _destroy
      }

      if (answer_type === "text") {
        answer_json["text_contains"] = alert.text_contains
      }

      let emailData = true

      if (alert.email.join("").trim() === "") {
        emailData = false
      }

      // include data about this answer value in the json payload only if this answer value's alert contains data
      if (
        emailData
        || alert.id
        || alert.enabled
        || alert.action_needed
      ) {
        json.feedback_question.feedback_answer_alerts_attributes.push(answer_json)
      }
    })

    return json
  }

  removeInvalidEmails() {
    let newState = {};
    this.setState(prevState => {
      for (let alert of this.state.invalidEmails) {
        const [bucket, answer_type] = alert.split("|")

        newState[bucket] = prevState[bucket]

        if (this.state.invalidEmails.includes(alert)) {
          let emailArray = newState[bucket][answer_type]["email"]
          for (let subindex in emailArray) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(emailArray[subindex].trim()) === null) {
              emailArray[subindex] = ""
            }
          }
        }
      }

      newState.invalidEmails = []
      this.props.setEmailsValid([])

      return newState
    })
  }

  validateAlertEmails(emails) {
    let valid = true

    for (let email of emails) {
      if (email.trim().length === 0) {
        continue
      }
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(email.trim()) === null) {
        valid = false
        break
      }
    }
    return valid
  }

  renderTabErrorIndicator(bucket) {
    if (this.state.invalidEmails.length > 0) {
      const invalidBuckets = this.state.invalidEmails.map(email => email.split('|')[0]);
      if (invalidBuckets.includes(bucket)) {
        return (
          <span className="notification-indicator red" />
        )
      }
    }
  }

  renderAlertFields(current_answer_type, location_id) {
    const five_answers = this.props.current_answer_quantity === "5"
    switch (current_answer_type) {
      case "boolean":
        return (
          <>
            {this.renderAlertRow("yes", location_id)}
            {this.renderAlertRow("no", location_id)}
          </>
        )

      case "stars":
        return (
          <>
            {this.renderAlertRow("one_star", location_id)}
            {this.renderAlertRow("two_stars", location_id)}
            {five_answers && this.renderAlertRow("three_stars", location_id)}
            {this.renderAlertRow("four_stars", location_id, !five_answers && "Three Stars")}
            {this.renderAlertRow("five_stars", location_id, !five_answers && "Four Stars")}
          </>
        )

      case "smileys":
        return (
          <>
            {this.renderAlertRow("very_unhappy", location_id)}
            {this.renderAlertRow("unhappy", location_id)}
            {five_answers && this.renderAlertRow("neutral", location_id)}
            {this.renderAlertRow("happy", location_id)}
            {this.renderAlertRow("very_happy", location_id)}
          </>
        )

      case "thumbs":
        return (
          <>
            {this.renderAlertRow("two_thumbs_down", location_id)}
            {this.renderAlertRow("one_thumb_down", location_id)}
            {five_answers && this.renderAlertRow("no_thumbs_up", location_id)}
            {this.renderAlertRow("one_thumb_up", location_id)}
            {this.renderAlertRow("two_thumbs_up", location_id)}
          </>
        )

      case "text":
        return (
          this.renderTextAlertRow(location_id)
        )

      default:
        return (
          <div>Something went wrong...</div>
        )
    }
  }

  renderAlertRow(answer_type, location_id, titleOverride = false) {
    let alert
    let bucket

    if (location_id === null) {
      alert = this.state.all_locations[answer_type]
      bucket = "all_locations"
    } else {
      alert = this.state[`location_${location_id}`][answer_type]
      bucket = "location_" + location_id
    }

    const placeholder = alert.enabled ? "Enter an email" : "Emails disabled";
    return (
      <div className="row alert-processing top-padding">
        <label className="column alert-title">{titleOverride || spaceAndCapitalize(answer_type)}:</label>
        <div className="email-checkbox-container">
          <input className="column" type="checkbox" name={`${bucket}|${answer_type}|enabled`} id={`${bucket}|${answer_type}|enabled`} checked={alert.enabled} onChange={this.handleCheckbox} />
          <label className="column checkbox-label" htmlFor={`${bucket}|${answer_type}|enabled`}>Enable emails?</label>
        </div>

        <div className="email-alert-row">
          {alert.email.map((email, index) => {
            let validEmail = true;

            if (email.trim().length > 0) {
              validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(email.trim())
            }

            return (
              <React.Fragment key={index}>
                  <div className={!alert.enabled || validEmail ? "hidden" : "red"}>Please enter a valid email</div>
                  <div className="email-row">
                    <input className={`column${validEmail ? "" : " invalid"}`} type="email" name={`${bucket}|${answer_type}|email`} id={index} placeholder={placeholder} value={email} disabled={alert.enabled === false} onChange={this.handleEmailChange} />
                    {alert.enabled && alert.email.length > 1 &&
                      <div id={index} data-name={`${bucket}|${answer_type}|email`} className="remove-button" onClick={this.handleRemoveEmail}>x</div>
                    }
                  </div>
              </React.Fragment>
            )
          })}
          {alert.enabled &&
            <div className="button" data-name={`${bucket}|${answer_type}|email`} id={answer_type + "_email"} onClick={this.handleAddEmail}>Add another email</div>
          }
        </div>

        <div>
          <HelpText page={'alertProcessing'} section={'action_needed'} />
          <input className="column" type="checkbox" name={`${bucket}|${answer_type}|action_needed`} id={`${bucket}|${answer_type}|action_needed`} checked={alert.action_needed} onChange={this.handleCheckbox} />
          <label className="column checkbox-label" htmlFor={`${bucket}|${answer_type}|action_needed`}>Require follow up actions for {spaceAndCapitalize(answer_type)} answers?</label>
        </div>
      </div>
    )
  }

  renderTextAlertRow(location_id) {
    let alert
    let bucket

    if (location_id === null) {
      alert = this.state.all_locations["text"]
      bucket = "all_locations"
    } else {
      alert = this.state[`location_${location_id}`]["text"]
      bucket = "location_" + location_id
    }

    const placeholder = alert.enabled ? "Enter an email" : "Emails disabled";

    return (
      <div className="row alert-processing top-padding">
        <label className="column alert-title">Text:</label>

        <div className="float-right">
          <input className="column" type="checkbox" name={`${bucket}|text|enabled`} id={`${bucket}|text|enabled`} checked={alert.enabled} onChange={this.handleCheckbox} />
          <label className="column checkbox-label" htmlFor={`${bucket}|text|enabled`}>Enable emails?</label>
        </div>

        <div className="email-alert-row">
          {alert.email.map((email, index) => {
            let validEmail = true;

            if (email.trim().length > 0) {
              validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.exec(email.trim())
            }

            return (
              <React.Fragment key={index}>
                  <div className={!alert.enabled || validEmail ? "hidden" : "red"}>Please enter a valid email</div>
                  <div className="email-row">
                    <input className={`column${validEmail ? "" : " invalid"}`} type="email" name={`${bucket}|text|email`} id={index} placeholder={placeholder} value={email} disabled={alert.enabled === false} onChange={this.handleEmailChange} />
                    {alert.enabled && alert.email.length > 1 &&
                      <div id={index} data-name={`${bucket}|text|email`} className="remove-button" onClick={this.handleRemoveEmail}>x</div>
                    }
                  </div>
              </React.Fragment>
            )
          })}
          {alert.enabled &&
            <div className="button" data-name={`${bucket}|text|email`} onClick={this.handleAddEmail}>Add another email</div>
          }
        </div>

        <label className="column alert-title">Text Contains:</label>
        <HelpText className="inline" page={'alertProcessing'} section={'textContains'} />
        <textarea className="column" name={`${bucket}|text|text_contains`} value={alert.text_contains} disabled={alert.enabled === false && alert.action_needed === false} onChange={this.handleChange} />

        <HelpText page={'alertProcessing'} section={'action_needed'} />
        <input className="column" type="checkbox" name={`${bucket}|text|action_needed`} id={`${bucket}|text|action_needed`} checked={alert.action_needed} onChange={this.handleCheckbox} />
        <label className="column checkbox-label" htmlFor={`${bucket}|text|action_needed`}>Require follow up action for matching text answers?</label>
      </div>
    )
  }

  render() {
    const { locations, tabIndex, alertsLoaded, locationsLoaded, unauthorized, error, errors } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (alertsLoaded === false || locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (!this.props.current_answer_type) {
      return <p className="bottom-padding center">Choose an answer type in order to set up alerts.</p>
    }

    let current_answer_type = this.props.current_answer_type

    if (alertsLoaded && locationsLoaded) {
      return (
        <div>
          <form className="settings big-settings">
            { renderErrors(errors, 'feedback_answer_alerts.id') }
            { renderErrors(errors, 'feedback_answer_alerts.email') }
            { renderErrors(errors, 'feedback_answer_alerts.enabled') }
            { renderErrors(errors, 'feedback_answer_alerts.answer_value') }

            <div>
              <HelpText page={'alertProcessing'} section={current_answer_type} />
              <h3 className="alert-processing title">Alert Processing</h3>

              <Tabs selectedIndex={tabIndex} onSelect={index => this.handleTabChange(index)}>
                <div className="tab-container">
                  <TabList>
                    <Tab key={0}>All Locations {this.renderTabErrorIndicator("all_locations")}</Tab>
                    {locations.map((location) => (
                      <Tab key={location.id}>{location.name} {this.renderTabErrorIndicator(`location_${location.id}`)}</Tab>
                    ))}
                  </TabList>
                </div>

                <TabPanel key={"all_locations"}>
                  { this.renderAlertFields(current_answer_type, null) }
                </TabPanel>

                {locations.map((location) => (
                  <TabPanel key={location.id}>
                    { this.renderAlertFields(current_answer_type, location.id) }
                  </TabPanel>
                ))}
              </Tabs>
            </div>
          </form>
        </div>
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.removeInvalidEmailsCount !== this.props.removeInvalidEmailsCount) {
      this.removeInvalidEmails()
    }

    if (prevProps.submitAlertsCount !== this.props.submitAlertsCount) {
      this.handleSubmit()
    }
  }

  fillLocations(collection, locations) {
    locations.map((location) => (
      collection[`location_${location.id}`] = {}
    ))

    return collection
  }

  fillDefaultData(collection) {
    for (var key in collection) {
      for (const alert_type of AlertProcessing.ANSWER_TYPES) {
        collection[key][alert_type] = {
          id: null,
          email: [""],
          enabled: false,
          action_needed: false,
          text_contains: ""
        }
      }
    }

    return collection
  }

  setUpData(data, locations) {
    let collection = {
      all_locations: {}
    }

    let invalidEmails = []

    collection = this.fillLocations(collection, locations)
    collection = this.fillDefaultData(collection)

    data.map((alert) => (
      [collection, invalidEmails] = this.processApiData(alert, collection, invalidEmails)
    ))

    return [collection, invalidEmails]
  }

  processApiData(data, collection, invalidEmails) {
    let alert
    let bucket

    if (data.location_id === null) {
      alert = collection["all_locations"][data.answer_value]
      bucket = "all_locations"
    }
    else {
      alert = collection[`location_${data.location_id}`][data.answer_value]
      bucket = `location_${alert.location_id}`
    }

    alert.id = data.id
    alert.email = data.email.split(";")
    alert.enabled = data.enabled
    alert.action_needed = data.action_needed
    alert.text_contains = data.text_contains

    if (this.validateAlertEmails(alert.email) === false) {
      invalidEmails.push(`${bucket}|${data.answer_value}`)
    }

    return [collection, invalidEmails]
  }

  initializeData(alerts, locations = this.state.locations) {
    let stateValues = {}

    const [collection, invalidEmails] = this.setUpData(alerts, locations)

    for (var key in collection) {
      stateValues[key] = collection[key]
    }

    stateValues["invalidEmails"] = invalidEmails
    stateValues["alertsLoaded"] = true

    this.setState(stateValues)
  }

  componentDidMount() {
    let id = this.props.id;


    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })

        if (id === null || id === undefined) {
          this.initializeData([], data)
          return
        }

        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_questions/${id}/get_feedback_alerts`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.initializeData(data)
          })
          .catch(error => {
            console.log(error)
            this.setState({ error, alertsLoaded: true })
          })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default AlertProcessing;
