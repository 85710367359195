import React, { Component } from 'react';

class CollapsibleContent extends Component {
  constructor(props) {
    super(props);

    this.showContent = this.showContent.bind(this);
    this.hideContent = this.hideContent.bind(this);
  }

  state = {
    showContent: false
  }

  showContent(event) {
    this.setState({showContent: true})

    event.preventDefault();
  }

  hideContent(event) {
    if (this.props.hideContent) {
      this.props.hideContent()
    }

    this.setState({showContent: false})

    event.preventDefault();
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  render() {
    const showContent = this.state.showContent || this.props.showContent;

    return (
      <>
        <div className="collapsible-button" onClick={showContent ? this.hideContent : this.showContent}>{this.props.buttonText}<span className="collapsible-icon">{showContent ? "-" : "+"}</span></div>
        {showContent &&
          <div className="collapsible-content">
            {this.props.children}
          </div>
        }
      </>
    );
  }
}

export default CollapsibleContent;
