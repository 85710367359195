import React, { useState } from "react";

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

const ConfirmForm = ({ setConfirmation, setPlannedEventId, setPage, organisation_id, location_id, service_id, staff_id, start_time }) => {
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [depositRequired] = useState(false); // TODO: Enable this with an API call once Stripe Connect is implemented

  const handleChange = (setState) => {
    return (event) => {
      const value = event.target.value

      setState(value)
    }
  }

  const handleConfirm = () => {
    let json = JSON.stringify({
      booking: {
        organisation_id: organisation_id,
        location_id: location_id,
        service_id: service_id,
        staff_id: staff_id,
        start_time: start_time,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone
      },
      deposit: depositRequired
    })

    request('POST', '/v1/b/confirm', json)
      .then(data => {
        if (depositRequired) {
          setPlannedEventId(data.id)
          setPage("deposit")
        }
        else {
          setConfirmation(data)
        }
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        if (error instanceof Error) {
          setError(error)
        }
        else {
          setErrors(error.errors)
        }
      })
  };

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  return (
    <>
      <h3>Confirm Selection</h3>
      <div className="confirm-form center">
        { errors &&
          <>
            <div className="error centered-text">{errors.join(". ")}</div>
            <br/>
          </>
        }

        <div className="text">First Name</div>
        <input type="first_name" name="first_name" value={first_name} autoComplete={"given-name"} onChange={handleChange(setFirstName)} />

        <div className="text">Last Name</div>
        <input type="last_name" name="last_name" value={last_name} autoComplete={"family-name"} onChange={handleChange(setLastName)} />

        <div className="text">Email<span className="red">*</span></div>
        <input type="email" name="email" value={email} autoComplete={"email"} onChange={handleChange(setEmail)} />

        <div className="text">Phone<span className="red">*</span></div>
        <input type="phone" name="phone" value={phone} autoComplete={"phone"} onChange={handleChange(setPhone)} />

        <button className="button no-margin center" onClick={handleConfirm}>{depositRequired ? "Continue to Deposit" : "Book"}</button>
      </div>
    </>
  )
}

export default ConfirmForm;
