import React from "react";

const Header = ({location_name, organisation_name}) => {
  let text

  if (location_name === organisation_name) {
    text = location_name
  }
  else {
    text = `${location_name} for ${organisation_name}`
  }

  return (
    <>
      <h1>You are now booking at {text}</h1>
    </>
  )
}

export default Header;
