import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './shims/ResizeObserver'

import Home from './pages/Home';
import Login from './pages/sessions/Login';
import Logout from './pages/sessions/Logout';
import Blank from './pages/sessions/Blank'
import ForgottenPassword from './pages/sessions/ForgottenPassword';
import PasswordReset from './pages/sessions/PasswordReset';
import SetPassword from './pages/sessions/SetPassword';

import SignUpStart from './pages/sign-up/Start';
import SignUpReturn from './pages/sign-up/Return';

import LocationShow from './pages/locations/Show';
import LocationIndex from './pages/locations/Index';

import FireLogIndex from './pages/fire-log/Index';

import ConfigurationIndex from './pages/configuration/Index';

import PeopleIndex from './pages/people/Index';
import MergePeople from './pages/people/MergePeople';
import SuggestedMerges from './pages/people/SuggestedMerges';

import PlannedEventsIndex from './pages/planned-events/Index';

import StaffFulfilmentIndex from './pages/staff-fulfilment/Index';
import StaffFulfilmentShow from './pages/staff-fulfilment/Show';
import DefaultRequiredAllocation from './pages/staff-fulfilment/DefaultRequiredAllocation';
import WeeklyRequiredAllocation from './pages/staff-fulfilment/WeeklyRequiredAllocation';
import DefaultWorkingPattern from './pages/staff-fulfilment/DefaultWorkingPattern';
import DefaultWorkingPatternUnassigned from './pages/staff-fulfilment/DefaultWorkingPatternUnassigned';
import WeeklyWorkingPattern from './pages/staff-fulfilment/WeeklyWorkingPattern';
import DefaultShiftAssignment from './pages/staff-fulfilment/DefaultShiftAssignment';
import WeeklyShiftAssignment from './pages/staff-fulfilment/WeeklyShiftAssignment';

import BulletinsIndex from './pages/bulletins/Index';

import AnalysisIndex from './pages/analysis/Index';

import ExportsIndex from './pages/exports/Index';
import ExportsEvents from './pages/exports/Events';
import ExportsFeedback from './pages/exports/Feedback';
import ExportsPeople from './pages/exports/People';
import ExportsVisitors from './pages/exports/Visitors';

import OutstandingActionsIndex from './pages/exports/OutstandingActions';
import ActionsTakenIndex from './pages/exports/ActionsTaken';

import ImportsIndex from './pages/imports/Index';

import UpgradeIndex from './pages/upgrade/Index';

import SupportIndex from './pages/support/Index';

import SettingsIndex from './pages/settings/Index';

import WorkedHoursShow from './pages/worked-hours/Show';
import WorkedHoursIndex from './pages/worked-hours/Index';

import VersionIndex from './pages/version/Index';

import Error404 from './pages/Error404';

import BookingForm from './booking/Index';

class App extends Component {
  render() {
    const staffFulfilmentRotaRoute = '/staff-fulfilment/:location_id/rotas/:rota_id';
    const staffFulfilmentRoute = '/staff-fulfilment/:location_id';

    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/weekly-feedback/:context' component={Home} />
          <Route exact path='/edit-organisation' component={Home} />

          <Route path='/login' component={Login} />
          <Route path='/logout' component={Logout} />
          <Route path='/blank' component={Blank} />
          <Route path='/forgotten-password' component={ForgottenPassword} />
          <Route path='/password-reset/token/:token' component={PasswordReset} />
          <Route path='/set-password/token/:token' component={SetPassword} />

          <Route path='/sign-up/return' component={SignUpReturn} />
          <Route path='/sign-up' component={SignUpStart} />

          <Route path='/location/:id/fire-log/history/:fire_log_id' component={LocationShow} />
          <Route path='/location/:id/weekly-feedback/:context' component={LocationShow} />
          <Route path='/location/:id/edit' component={LocationShow} />
          <Route path='/location/:id' component={LocationShow} />
          <Route path='/locations/new' component={LocationIndex} />
          <Route path='/locations' component={LocationIndex} />

          <Route path='/fire-log/history/:fire_log_id' component={FireLogIndex} />
          <Route path='/fire-log/history' component={FireLogIndex} />
          <Route path='/fire-log' component={FireLogIndex} />

          <Route path='/configuration/:page/:id/question/new' component={ConfigurationIndex} />
          <Route path='/configuration/:page/:id/question/:question_id' component={ConfigurationIndex} />
          <Route path='/configuration/:page/new' component={ConfigurationIndex} />
          <Route path='/configuration/:page/:id' component={ConfigurationIndex} />
          <Route path='/configuration/:page' component={ConfigurationIndex} />
          <Route path='/configuration' component={ConfigurationIndex} />

          <Route path='/people/new' component={PeopleIndex} />
          <Route path='/people/:id/events/:event_id' component={PeopleIndex} />
          <Route path='/people/:id' component={PeopleIndex} />
          <Route path='/people' component={PeopleIndex} />
          <Route path='/merge-people' component={MergePeople} />
          <Route path='/suggested-merge-people' component={SuggestedMerges} />

          <Route path='/planned-events/:location_id/:view/:date/new' component={PlannedEventsIndex} />
          <Route path='/planned-events/:location_id/:view/:date/:id' component={PlannedEventsIndex} />
          <Route path='/planned-events/:location_id/:view/:date' component={PlannedEventsIndex} />
          <Route path='/planned-events/:location_id' component={PlannedEventsIndex} />
          <Route path='/planned-events/' component={PlannedEventsIndex} />

          <Route path='/rota-types/new' component={StaffFulfilmentIndex} />
          <Route path='/rota-types/:id' component={StaffFulfilmentIndex} />

          {
            // Dynamically render Staff Fulfilment routes so we don't need to duplicate blocks for RotaTypes.
            //
            //   staffFulfilmentRotaRoute: "/staff-fulfilment/:location_id/rotas/:rota_id
            //   staffFulfilmentRoute: "/staff-fulfilment/:location_id"
            //
          }
          {[staffFulfilmentRotaRoute, staffFulfilmentRoute].map((baseRoute, index) => (
            [
              <Route path={`${baseRoute}/required-allocation/template/new`} component={DefaultRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation/template/:id`} component={DefaultRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation/template`} component={DefaultRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation/weekly/new`} component={WeeklyRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation/weekly/:id`} component={WeeklyRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation/weekly`} component={WeeklyRequiredAllocation} />,
              <Route path={`${baseRoute}/required-allocation`} component={DefaultRequiredAllocation} />,

              <Route path={`${baseRoute}/working-pattern-unassigned/template/new`} component={DefaultWorkingPatternUnassigned} />,
              <Route path={`${baseRoute}/working-pattern-unassigned/template/:id`} component={DefaultWorkingPatternUnassigned} />,
              <Route path={`${baseRoute}/working-pattern-unassigned/template`} component={DefaultWorkingPatternUnassigned} />,
              <Route path={`${baseRoute}/working-pattern-unassigned/`} component={DefaultWorkingPatternUnassigned} />,

              <Route path={`${baseRoute}/working-pattern/template/new`} component={DefaultWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/template/:id`} component={DefaultWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/template`} component={DefaultWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/weekly/new`} component={WeeklyWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/weekly/:id`} component={WeeklyWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/weekly`} component={WeeklyWorkingPattern} />,
              <Route path={`${baseRoute}/working-pattern/`} component={DefaultWorkingPattern} />,

              <Route path={`${baseRoute}/shift-assignment/template/new`} component={DefaultShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/template/:id`} component={DefaultShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/template`} component={DefaultShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/weekly/new`} component={WeeklyShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/weekly/:id`} component={WeeklyShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/weekly`} component={WeeklyShiftAssignment} />,
              <Route path={`${baseRoute}/shift-assignment/`} component={DefaultShiftAssignment} />,

              <Route path={baseRoute} component={StaffFulfilmentShow} />
            ]
          ))}

          <Route path='/staff-fulfilment/' component={StaffFulfilmentIndex} />

          <Route path='/bulletins/new' component={BulletinsIndex} />
          <Route path='/bulletins/:id' component={BulletinsIndex} />
          <Route path='/bulletins' component={BulletinsIndex} />

          <Route path='/worked-hours/:id' component={WorkedHoursShow} />
          <Route path='/worked-hours/' component={WorkedHoursIndex} />

          <Route path='/analysis/:tab/:chart/:context/:context2' component={AnalysisIndex} />
          <Route path='/analysis/:tab/:chart/:context' component={AnalysisIndex} />
          <Route path='/analysis/:tab' component={AnalysisIndex} />
          <Route path='/analysis' component={AnalysisIndex} />

          <Route path='/exports/events/:id' component={ExportsEvents} />
          <Route path='/exports/events' component={ExportsEvents} />
          <Route path='/exports/feedback/:id' component={ExportsFeedback} />
          <Route path='/exports/feedback' component={ExportsFeedback} />
          <Route path='/exports/people' component={ExportsPeople} />
          <Route path='/exports/visitors/:id' component={ExportsVisitors} />
          <Route path='/exports/visitors' component={ExportsVisitors} />
          <Route path='/exports/outstanding-actions/new' component={OutstandingActionsIndex} />
          <Route path='/exports/outstanding-actions/:id' component={OutstandingActionsIndex} />
          <Route path='/exports/outstanding-actions' component={OutstandingActionsIndex} />
          <Route path='/exports/actions-taken/:id' component={ActionsTakenIndex} />
          <Route path='/exports/actions-taken' component={ActionsTakenIndex} />
          <Route path='/exports' component={ExportsIndex} />

          <Route path='/imports/new' component={ImportsIndex} />
          <Route path='/imports/:id' component={ImportsIndex} />
          <Route path='/imports' component={ImportsIndex} />

          <Route exact path='/settings/users/new' component={SettingsIndex} />
          <Route exact path='/settings/users/:user_id' component={SettingsIndex} />
          <Route path='/settings/' component={SettingsIndex} />

          <Route path='/upgrade' component={UpgradeIndex} />

          <Route path='/support/new' component={SupportIndex} />
          <Route path='/support/faqs/:faq_id' component={SupportIndex} />
          <Route path='/support/faqs/' component={SupportIndex} />
          <Route path='/support/:id' component={SupportIndex} />
          <Route path='/support/' component={SupportIndex} />

          <Route path='/version' component={VersionIndex} />

          <Route path='/:page_name/:organisation_info/:location_info' component={BookingForm} />

          <Route component={Error404} />
        </Switch>
      </Router>
    )
  }
}

export default App;
