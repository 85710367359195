import React, { Component } from 'react';
import { Redirect, withRouter} from 'react-router-dom';
import UpdatePlannedEvent from '../../components/planned-events/UpdatePlannedEvent';
import CreatePlannedEvent from '../../components/planned-events/CreatePlannedEvent';

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

import { Calendar, luxonLocalizer } from 'react-big-calendar';
import "../../components/planned-events/react-big-calendar.css";
import { DateTime } from 'luxon'

class PlannedEventsCalendar extends Component {
  constructor(props) {
    super(props);

    this.handleView = this.handleView.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  state = {
    planned_events: [],
    view: "week",
    loading: false,
    loaded: false,
    error: null,

    start_date: "",
    end_date: "",
    earliest_start_date: "",
    latest_end_date: "",

    selectedDate: null,
    editId: null,
    newPanelToggle: false,
    editPanelToggle: false
  };

  currentURL(){
    const { match: { params } } = this.props;
    return `/planned-events/${params.location_id}/${params.view}/${params.date}`
  }

  formatData(data) {
    for (let planned_event of data) {
      planned_event.start = new Date(Date.parse(planned_event.start_date))
      planned_event.end = new Date(Date.parse(planned_event.end_date))
      delete planned_event.start_date
      delete planned_event.end_date
    }
    return data
  }

  formatDate(date) {
    const day = ("0" + (date.getDate())).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    return this.props.match.params.view === "month" ? `${year}-${month}` : `${year}-${month}-${day}`
  }

  handleView(newView) {
    this.setState({
      view: newView
    })
    this.props.history.push(`/planned-events/${this.props.match.params.location_id}/${newView}/${this.props.match.params.date}`)
  }

  handleRangeChange(output) {
    const start_date = output.start || output[0]
    const end_date =  output.end || output[output.length - 1]

    if (start_date < this.state.earliest_start_date || end_date > this.state.latest_end_date) {
      const earliest_start_date = start_date < this.state.earliest_start_date ? start_date : this.state.earliest_start_date
      const latest_end_date = end_date < this.state.latest_end_date ? end_date : this.state.latest_end_date

      this.setState({ loading: true })

      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      const params = `planned_event[location_id]=${this.props.location_id}&planned_event[start_date]=${this.dateToParam(start_date)}&planned_event[end_date]=${this.dateToParam(end_date)}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/planned_events?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data =>
        {
          const formattedData = this.formatData(data);
          const incomingEventIDs = formattedData.map(incomingEvent => incomingEvent.id)
          this.setState((prevState) => ({
            loading: false,
            earliest_start_date: earliest_start_date,
            latest_end_date: latest_end_date,
            planned_events: [...prevState.planned_events.filter(oldEvent => !incomingEventIDs.includes(oldEvent.id)), ...formattedData]
          }))

        })
        .catch(error => this.setState({ error, loaded: true }))
    }
    // need to let onView override this.state.view
    setTimeout(() => {this.props.history.push(this.handleURL(start_date))}, 5)
  }

  handleSelectEvent(event) {
    this.props.history.push(`/planned-events/${this.props.match.params.location_id}/${this.state.view}/${this.props.match.params.date}/${event.id}`)
    this.setState({
      editId: event.id,
      editPanelToggle: true
    });
  }

  handleURL(start_date) {
    // when calendar shows days from previous month, force URL to display selected month
    if (this.state.view === "month" && start_date.getDate() !== 1) {
      start_date = new Date(start_date.getFullYear(), start_date.getMonth() + 1, 1)
    }

    return `/planned-events/${this.props.match.params.location_id}/${this.state.view}/${this.formatDate(start_date)}`
  }

  handleNewPanel(slotInfo) {
    // select first date in a range if drag slected
    const selection = slotInfo.slots ? slotInfo.slots[0] : slotInfo.start

    this.props.history.push(`/planned-events/${this.props.match.params.location_id}/${this.props.match.params.view}/${this.props.match.params.date}/new`)
    this.setState({
      selectedDate: selection,
      newPanelToggle: true
    });
  }

  closePanel() {
    this.setState({
      newPanelToggle: false,
      editPanelToggle: false,
      editId: null
    });
    this.props.history.push(this.currentURL());
    this.reloadCacheFromContext();
  }

  eventPropGetter(event, start, end, isSelected) {
    return ({
      ...(event.visited && !event.in_progress && {
        className: 'green-event',
      }),
      ...(event.visited && event.in_progress && {
        className: 'yellow-event',
      }),
      ...(!event.visited && end < new Date() && {
        className: 'grey-event',
      }),
      ...(!event.visited && event.resident_host && end > new Date() && {
        className: 'purple-event',
      }),
    })
  }

  render() {
    const { planned_events, view, selectedDate, editId, newPanelToggle, editPanelToggle, loading, loaded, error, unauthorized, invalid_params } = this.state;
    const containerClass = loading ? "loading" : ""

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (invalid_params === true) {
      return <p>Waiting for valid date range ...</p>;
    }

    if (!this.props.location_id) {
      return <p>Please select a Location.</p>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 })
      return (
        <div className="relative">
          <div className="legend-box right">
            <div><strong><em>Colour Key:</em></strong></div>
            <div className="grey-event legend">Past Planned Events where no visitors arrived appear in this colour.</div>
            <div className="green-event legend">Past Planned Events where at least one visitor arrived and all visitors have left appear in this colour.</div>
            <div className="yellow-event legend">In-progress Planned Events where at least one visitor has arrived but not checked out appear in this colour.</div>
            <div className="purple-event legend">Future Planned Events where a Resident is being visited appear in this colour.</div>
            <div className="legend">All other future Planned Events appear in this colour.</div>
          </div>

          <div className={containerClass}>
            <Calendar
              localizer={localizer}
              defaultDate={this.props.date}
              eventPropGetter={this.eventPropGetter}
              events={planned_events}
              selectable={true}
              onRangeChange={this.handleRangeChange}
              onSelectEvent={this.handleSelectEvent}
              onSelecting={this.handleNewPanel}
              onSelectSlot={this.handleNewPanel}
              onView={this.handleView}
              popup={true}
              startAccessor="start"
              endAccessor="end"
              view={view}
              views={["month", "week", "day"]}
              style={{ height: 800 }}
            />
          </div>

          <SlidingPane isOpen={newPanelToggle} title="New Planned Event" width="60%"
            onRequestClose={() => {
              this.setState({ newPanelToggle: false });
              this.props.history.push(this.currentURL());
            }}>
            <CreatePlannedEvent date={selectedDate} location_id={this.props.location_id} closePanel={this.closePanel}  previousURL={this.currentURL()} />
          </SlidingPane>

          <SlidingPane isOpen={editPanelToggle} title="Edit Planned Event" width="60%"
            onRequestClose={() => {
              this.closePanel()
            }}>
            <UpdatePlannedEvent id={editId} closePanel={this.closePanel} date={this.dateToParam(this.props.date)} />
          </SlidingPane>
        </div>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.newPanelToggle && props.match.params.id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.match.params.id
      }
    }
    else if (state.editPanelToggle && props.match.params.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.newPanelToggle && !props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: false
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith(props.match.params.date)) {
      return {
        view: props.match.params.view,
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        view: props.match.params.view,
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.match.params.id !== undefined) {
      return {
        view: props.match.params.view,
        newPanelToggle: false,
        editPanelToggle: true,
        editId: props.match.params.id
      }
    }
    else {
      return null
    }
  }

  dateToParam(date){
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${year}/${month}/${day}`
  }

  reloadCacheFromContext() {
    this.setState({ loading: true })

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const urlDate = new Date(this.props.match.params.date)

    // After closing the Day panel, reload the cache grabbing events a month before and a month after the date in URL parameters
    const startDate = new Date (new Date(this.props.match.params.date).setMonth(urlDate.getMonth() - 1))
    const endDate = new Date (new Date(this.props.match.params.date).setMonth(urlDate.getMonth() + 1))

    const params = `planned_event[location_id]=${this.props.location_id}&planned_event[start_date]=${this.dateToParam(startDate)}&planned_event[end_date]=${this.dateToParam(endDate)}`

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/planned_events?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data =>
      {
        const formattedData = this.formatData(data);
        this.setState({
          earliest_start_date: startDate,
          latest_end_date: endDate,
          planned_events: formattedData,
          loading: false
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  componentDidMount() {
    // deep linking
    if (this.props.match.params.view) {
      this.setState({
        view: this.props.match.params.view
      });
    }

    if (this.props.location.pathname.endsWith("/new") && this.state.newPanelToggle === false) {
      this.setState({
        newPanelToggle: true
      });
    }
    else if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    if (this.props.location_id && this.props.date) {
      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      // Initial index range should be 6 days before the start of the month and 6 days after the end of the month to account for all scenarios
      const startOfRange = new Date(new Date(this.props.date.getFullYear(), this.props.date.getMonth(), -6))
      const endOfRange = new Date(this.props.date.getFullYear(), this.props.date.getMonth() + 1, 7)

      const params = `planned_event[location_id]=${this.props.location_id}&planned_event[start_date]=${this.dateToParam(startOfRange)}&planned_event[end_date]=${this.dateToParam(endOfRange)}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/planned_events?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data =>
        {
          const formattedData = this.formatData(data);
          this.setState({
            earliest_start_date: startOfRange,
            latest_end_date: endOfRange,
            planned_events: formattedData,
            loaded: true
          })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
  }
}

export default withRouter(PlannedEventsCalendar);
