import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';
import ListOfRelationships from '../../components/relationships/ListOfRelationships';
import PersonEvents from '../../components/people/PersonEvents';
import Audits from '../../components/shared/Audits';
import TypeOfPersonSelect from '../../components/shared/TypeOfPersonSelect';
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'
import CollapsibleContent from '../../components/shared/CollapsibleContent'

import { adminUser, managerUser, renderErrorWarning, renderErrors, capitalize, pluralize } from '../../utilities/Forms.js'
import { renderFullName, hideResidentsCheck } from '../../utilities/Generic.js'
import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

import DefaultAvatar from '../../images/default_avatar.jpg';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class UpdatePerson extends Component {

  constructor(props) {
    super(props);

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleModalSelect = this.handleModalSelect.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleAddCustomAttribute = this.handleAddCustomAttribute.bind(this);
    this.handleCustomAttributeChange = this.handleCustomAttributeChange.bind(this);
    this.handleRemoveSuggestedMergeException = this.handleRemoveSuggestedMergeException.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.clearImageUpload = this.clearImageUpload.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeletePersonImport = this.handleDeletePersonImport.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    alternate_name: "",
    display_name: "",
    phone: "",
    email: "",
    type_of_person: "",
    location_ids: [],
    role_ids: [],
    service_ids: [],
    tag_ids: [],
    covid_pass_expiry_date: "",
    covid_pass_exemption: false,
    exemption_date: "",
    editor_name: "",
    editor_id: "",
    person_custom_attributes: [],
    person_imports: [],
    suggested_merge_exceptions: [],
    redlist: false,
    contracted_hours: "",
    visit_all: "",
    picture: null,
    upload_file: null,
    photo_opt_out: null,

    showAddCustomAttributeModal: false,
    newCustomAttribute: {
      value: ""
    },

    hideResidents: hideResidentsCheck(),
    type_of_organisation: localStorage.type_of_organisation || "Social Care",

    tabIndex: 0,

    loaded: false,
    locationsLoaded: false,
    rolesLoaded: false,
    servicesLoaded: false,
    tagsLoaded: false,
    customAttributesLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event, targetKey, ids = true) {
    const name = ids ? parseInt(event.target.name) : event.target.name;
    const value = event.target.checked;

    let array = this.state[targetKey]

    if (value === true) {
      if (!array.includes(name)) {
        array.push(name)
      }
    }
    else if (value === false) {
      if (array.includes(name)) {
        let index = array.indexOf(name)
        array.splice(index, 1)
      }
    }

    this.setState({[targetKey]: array});
  }

  handleCustomAttributeChange(event) {
    let custom_attribute_id = parseInt(event.target.name);
    let value = event.target.value;

    let person_custom_attributes = this.state.person_custom_attributes;

    let index = person_custom_attributes.findIndex(element => element.custom_attribute_id === custom_attribute_id)

    if (person_custom_attributes[index] !== undefined) {
      person_custom_attributes[index].value = value;
    }

    this.setState({person_custom_attributes: person_custom_attributes});
  }

  handleRemoveSuggestedMergeException(event) {
    const value = event.target.parentNode.id;

    this.setState((prevState) => ({
      suggested_merge_exceptions: prevState.suggested_merge_exceptions.filter((suggested_merge_exception) => suggested_merge_exception !== value)
    }))
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  clearImageUpload(event) {
    this.setState({'picture': null});
  }

  handleTabChange(index) {
    this.setState({ tabIndex: index });
  }

  handleSubmit(event) {
    const formData = new FormData()
    formData.append('person[first_name]', this.state.first_name)
    formData.append('person[last_name]', this.state.last_name)
    formData.append('person[alternate_name]', this.state.alternate_name)
    formData.append('person[display_name]', this.state.display_name)
    formData.append('person[phone]', this.state.phone)
    formData.append('person[email]', this.state.email)
    formData.append('person[type_of_person]', this.state.type_of_person)
    formData.append('person[covid_pass_exemption]', this.state.covid_pass_exemption)
    formData.append('person[redlist]', this.state.redlist)
    formData.append('person[contracted_hours]', this.state.contracted_hours)
    formData.append('person[visit_all]', this.state.visit_all)
    formData.append('person[photo_opt_out]', this.state.photo_opt_out)

    if (this.state.location_ids.length > 0) {
      this.state.location_ids.map((location_id, index) => (
        formData.append(`person[location_ids][]`, location_id)
      ))
    }
    else {
      formData.append(`person[location_ids][]`, "_empty")
    }

    if (this.state.role_ids.length > 0) {
      this.state.role_ids.map((role_id, index) => (
        formData.append(`person[role_ids][]`, role_id)
      ))
    }
    else {
      formData.append(`person[role_ids][]`, "_empty")
    }

    if (this.state.service_ids.length > 0) {
      this.state.service_ids.map((service_id, index) => (
        formData.append(`person[service_ids][]`, service_id)
      ))
    }
    else {
      formData.append(`person[service_ids][]`, "_empty")
    }

    if (this.state.tag_ids.length > 0) {
      this.state.tag_ids.map((tag_id, index) => (
        formData.append(`person[tag_ids][]`, tag_id)
      ))
    }
    else {
      formData.append(`person[tag_ids][]`, "_empty")
    }

    if (this.state.suggested_merge_exceptions.length > 0) {
      this.state.suggested_merge_exceptions.map((suggested_merge_exception, index) => (
        formData.append(`person[suggested_merge_exceptions][]`, suggested_merge_exception)
      ))
    }
    else {
      formData.append(`person[suggested_merge_exceptions][]`, "_empty")
    }

    let person_custom_attributes_attributes = this.state.person_custom_attributes.map((custom_attribute) => {
      return {
        id: custom_attribute.id,
        custom_attribute_id: custom_attribute.custom_attribute_id,
        value: custom_attribute.value
      }
    })

    if (person_custom_attributes_attributes.length > 0) {
      person_custom_attributes_attributes.forEach((person_custom_attributes_attribute, index) => {
        formData.append(`person[person_custom_attributes_attributes][${index}][custom_attribute_id]`, person_custom_attributes_attribute.custom_attribute_id);
        formData.append(`person[person_custom_attributes_attributes][${index}][value]`, person_custom_attributes_attribute.value);
        if (person_custom_attributes_attribute.id) {
          formData.append(`person[person_custom_attributes_attributes][${index}][id]`, person_custom_attributes_attribute.id);
        }
      })
    }
    else {
      formData.append(`person[person_custom_attributes_attributes][]`, "_empty")
    }

    if (this.state.upload_file !== null) {
      formData.append('person[picture]', this.state.upload_file)
    }

    var requestOptions = {
      method: 'PUT',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    let id = this.props.id;

    let bigError = false
    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        bigError = true
        return response.json()
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (bigError) {
        this.setState({ error: data })
      }
      else if (errorsInResponse) {
        this.setState({ errors: data })
      }
      else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Person updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  handleDeletePersonImport(person_import_id) {
    request('DELETE', `/v1/a/people/${this.props.id}/destroy_person_import?person[person_import_id]=${person_import_id}`, null, () => {this.setState({ unauthorized: true })})
      .then(data => {
        this.setState({
          person_imports: data,
          tabIndex: 0,
          loaded: true
        })
        sessionStorage.setItem("updateSuccess", "Person Import deleted successfully!");
      })
      .catch(error => {
        reportErrorToHoneybadger(error)

        this.setState({ error, loaded: true })
      })
  }

  covidPassExpiryDate() {
    if (this.state.covid_pass_expiry_date === "") {
      return "This person is yet to scan a Covid vaccination pass with Digital Reception."
    }
    else {
      return this.state.covid_pass_expiry_date
    }
  }

  renderPersonPicture(picture) {
    if (picture === null) {
      return (
        <>
          <img src={DefaultAvatar} style={{maxWidth: "100%"}} alt="Placeholder"/>
          <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} />
        </>
      )
    }
    else {
      return (
        <div className="inline-image">
          <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${picture}`} style={{maxWidth: "100%"}} alt={`${this.state.full_name}`}></img>
          <div className="small right button" onClick={this.clearImageUpload}>Clear image</div>
        </div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderLocationMessage() {
    // Notify Manager users that they will not be able to see the Person if they don't assign a location.
    // This is because we only show People assigned to the Managers locations on the Index page
    if (managerUser() && this.state.location_ids.length === 0) {
      return (
        <NoticeBox type="info" text="You must select a Location, otherwise this Person will be hidden from view." />
      )
    }
  }

  renderExemptionDetails(exemption_date, editor_id, editor_name) {
    if (exemption_date && editor_id && editor_name){
      return (
        <div>
          <div className="row">
            <HelpText page={'person'} section={'exemption_date'} />

            <label className="column">Exemption Date:</label>
            <label className="right-column" type="exemption_editor" name="exemption_date">{exemption_date}</label>
          </div>

          <div className="row">
            <HelpText page={'person'} section={'exemption_editor'} />

            <label className="column">Exemption Editor:</label>
            <Link to={`/settings/users/${editor_id}`} className="right-column" type="exemption_editor" name="exemption_editor">{editor_name}</Link>
          </div>
        </div>
      )
    }
  }

  renderCustomAttributes(person_custom_attributes) {
    if (person_custom_attributes.length > 0) {
      return (
        <div className="row">
          <label className="column">Custom Attributes:</label>
          <table className="checkbox-table custom-attributes-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
              {person_custom_attributes.map((custom_attribute) => (
                <tr key={custom_attribute.custom_attribute_id}>
                  <td>
                    <Link to={`/configuration/custom-attributes/${custom_attribute.custom_attribute_id}`}>{custom_attribute.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input data-name={custom_attribute.name} name={custom_attribute.custom_attribute_id} value={custom_attribute.value} onChange={this.handleCustomAttributeChange} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }

  renderAddCustomAttributeModal() {
    if (this.state.showAddCustomAttributeModal) {
      let usedCustomAttributeIds = this.state.person_custom_attributes.map(person_custom_attribute => person_custom_attribute.custom_attribute_id)
      let custom_attributes = this.state.custom_attributes

      // Remove Custom Attributes from select which already have associated Person Custom Attributes
      custom_attributes = custom_attributes.filter((custom_attribute) => !usedCustomAttributeIds.includes(custom_attribute.id))

      return (
        <div className="modal-container">
          <div className="modal-content">
            <label className="column">Add a new Custom Attribute:</label>
            <select name="custom_attribute_id" onChange={this.handleModalSelect} >
              <option value="">Select</option>
              {custom_attributes.map((custom_attribute) => (
                <option value={custom_attribute.id} key={custom_attribute.id}>{custom_attribute.name}</option>
              ))}
            </select>
            <input className="value" name="value" value={this.state.newCustomAttribute.value} onChange={this.handleModalChange}/>

            <button className="modal-button" value="yes" onClick={this.handleAddCustomAttribute}>Add attribute</button>
            <button className="modal-button" value="no" onClick={this.closeModal}>Cancel</button>
          </div>
        </div>
      )
    }
  }

  handleModalChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState(existingState => ({
      newCustomAttribute: {
        ...existingState.newCustomAttribute,
        [name]: value
      }
    }))
  }

  handleModalSelect(event) {
    let id = parseInt(event.target.value);

    if (id){
      let index = this.state.custom_attributes.findIndex((custom_attribute) => custom_attribute.id === id)
      let name = this.state.custom_attributes[index].name

      this.setState(existingState => ({
        newCustomAttribute: {
          ...existingState.newCustomAttribute,
          custom_attribute_id: id,
          name: name,
        }
      }))
    }
  }

  handleAddCustomAttribute(event) {
    event.preventDefault();

    let person_custom_attributes = this.state.person_custom_attributes

    person_custom_attributes.push(this.state.newCustomAttribute)

    this.setState({
      person_custom_attributes: person_custom_attributes,
      newCustomAttribute: {
        value: ""
      }
    })
    this.closeModal(event)
  }

  closeModal(event) {
    this.setState({
      showAddCustomAttributeModal: false,
      newCustomAttribute: {
        value: ""
      }
    })
    event.preventDefault()
  }

  openModal(event) {
    this.setState({
      showAddCustomAttributeModal: true,
      newCustomAttribute: {
        value: ""
      }
    })
    event.preventDefault()
  }

  renderPersonImported() {
    if (this.state.person_imports.length > 0) {
      return (
        <div className="information-box">
          <FontAwesomeIcon icon={faInfo} className="icon" />This Person is associated with data from an Import.
        </div>
      )
    }
  }

  renderImportWarnings() {
    if (this.state.person_imports.length > 1) {
      return (
        <div>
          {this.renderUniqueImports()}
          {this.renderDuplicatedImports()}
        </div>
      )
    }
  }

  renderUniqueImports() {
    let import_ids = this.state.person_imports.map(person_import => person_import.import_id)
    if (this.hasDifferences(import_ids)) {
      // remove duplicates by turning into set, use set values for new array
      let set = new Set()
      let uniqueArray = [...set]

      return (
        <div className="warning-box">
          <label className="column">
            <strong>WARNING:</strong> This person is associated with data from multiple Imports:
            {uniqueArray.map((import_id, index, array) => (
              <span key={import_id}> <Link to={`/imports/${import_id}`}>{import_id}</Link>{this.renderComma(index, array)}</span>
            ))}
            . If both Imports are active, this Person might get updated with conflicting data.
          </label>
        </div>
      )
    }
  }

  renderDuplicatedImports() {
    let import_ids = this.state.person_imports.map(person_import => person_import.import_id)

    if (this.hasDuplicates(import_ids)) {
      let duplicatesArray = []

      for (const value of import_ids) {
        // compare the first and last index of a value
        if(import_ids.indexOf(import_ids[value]) !== import_ids.lastIndexOf(import_ids[value])) {
          // add values of duplicates into duplicatesArray
          duplicatesArray.push(value)
        }
      }
      // remove duplicates by turning into set, use set values for new array
      let set = new Set(duplicatesArray)
      let uniqueArray = [...set]

      return (
        <div className="warning-box">
          <label className="column">
            <strong>WARNING:</strong> This person is {this.renderAlso(import_ids)}associated with multiple entries from the same Import{this.renderPlural(uniqueArray.length)}:
            {uniqueArray.map((import_id, index, array) => (
              <span key={import_id}> <Link to={`/imports/${import_id}`}>{import_id}</Link>{this.renderComma(index, array)}</span>
            ))}
            . This indicates a likelihood that Duplicate People exist on the source data for your Imports!
          </label>
        </div>
      )
    }
  }

  hasDuplicates(array) {
    // Sets only store unique elements
    const set = new Set(array)

    // if array is longer than set there must be duplicates
    return array.length > set.size
  }

  hasDifferences(array) {
    // Sets only store unique elements
    const set = new Set(array)

    // if set has more than one value there must be multiple unique values
    return set.size > 1
  }

  renderComma(index, array) {
    if (index < array.length-1){
      return ","
    }
  }

  renderAlso(import_ids) {
    if (this.hasDifferences(import_ids)) {
      return "also "
    }
  }

  renderPlural(arrayLength){
    if (arrayLength > 1) {
      return "s"
    }
  }

  renderPersonImportFields(person_import) {
    return (
      <div className="table soft-table small-bottom-margin">
        {person_import.person_data &&
          <>
            <div className="tr">
              <label className="pcolumn strong">First Name:</label>
              <label className="prightcolumn">{person_import.person_data.first_name}</label>
            </div>

            <div className="tr">
              <label className="pcolumn strong">Last Name:</label>
              <label className="prightcolumn">{person_import.person_data.last_name}</label>
            </div>

            <div className="tr">
              <label className="pcolumn strong">Phone:</label>
              <label className="prightcolumn">{person_import.person_data.phone}</label>
            </div>

            <div className="tr">
              <label className="pcolumn strong">Email:</label>
              <label className="prightcolumn">{person_import.person_data.email}</label>
            </div>
          </>
        }
        <div className="tr">
          <label className="pcolumn strong">Import ID:</label>
          <Link to={`/imports/${person_import.import_id}`}><label className="prightcolumn link">{person_import.import_id}</label></Link>
        </div>
      </div>
    )
  }

  renderPersonImports() {
    if (this.state.person_imports.length === 0) {
      return
    }

    return (
      <div className="bottom-margin">
        <CollapsibleContent buttonText={`View/Delete Person Import${this.state.person_imports.length > 1 ? "s" : ""}`}>
          <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.handleTabChange(index)}>
            <div className={`tab-container${this.state.person_imports.length > 1 ? "" : " hidden"}`}>
              <TabList>
                {this.state.person_imports.map((person_import, index) => (
                  <Tab key={person_import.id}>{index + 1} </Tab>
                ))}
              </TabList>
            </div>

            {this.state.person_imports.map(person_import => (
              <TabPanel key={person_import.id}>
                {this.renderPersonImportFields(person_import)}

                { (adminUser() || managerUser()) &&
                  <ModalContainerTrigger className="centered button small" closeName="Cancel" triggerText="Delete Person Import" modalTitle="Are you sure you want to Delete this Person Import?">
                    {this.renderPersonImportFields(person_import)}

                    <div className="centered button" onClick={() => this.handleDeletePersonImport(person_import.id)}>Confirm</div>
                  </ModalContainerTrigger>
                }
              </TabPanel>
            ))}
          </Tabs>
        </CollapsibleContent>
      </div>
    )
  }

  renderSuggestedMergeExceptions(suggested_merge_exceptions) {
    if (suggested_merge_exceptions.length > 0) {
      return (
        <div className="row">
          <div className="table big-table">
            <div className="tr heading">
              <div className="th">Exception</div>
              <div className="th">Remove?</div>
            </div>
            {suggested_merge_exceptions.map((suggested_merge_exception) => (
              <div className="tr" key={suggested_merge_exception} id={suggested_merge_exception} >
                <div className="td" >{this.renderSuggestedMergeException(suggested_merge_exception)}</div>
                <div className="td center-cell link" name={suggested_merge_exception} onClick={this.handleRemoveSuggestedMergeException}>Remove</div>
              </div>
            ))}
          </div>
          <div className="top-padding"/>
        </div>
      )
    }
  }

  renderSuggestedMergeException(exception) {
  // eslint-disable-next-line
    switch(exception) {
      case "matching details, different locations":
        return (<span>No one with <strong>0 assigned Locations in common</strong><HelpText className="tr-helptext" page={'suggested_merges'} section={'cross_location_matching'} /></span>)
      case "matching name, different logical types":
        return (<span>No one with a <strong>very different type of person</strong><HelpText className="tr-helptext" page={'suggested_merges'} section={this.state.hideResidents ? 'logical_types_of_people_without_residents' : 'logical_types_of_people'} /></span>)
    }
  }

  renderRolesForm() {
    const { type_of_person, role_ids, roles, errors } = this.state;

    if (type_of_person === "staff" || type_of_person === "contractor") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'roles'} />

          <label className="column">Roles:</label>{ renderErrors(errors, 'role') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Add person to?</th>
              </tr>
              {roles.map((role) => (
                <tr key={role.id}>
                  <td>
                    <Link to={`/configuration/roles/${role.id}`}>{role.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={role.name} name={role.id} checked={role_ids.includes(role.id)} onChange={(event) => this.handleMultiCheckbox(event, "role_ids")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return(renderErrors(errors, 'role'))
    }
  }

  renderServicesForm() {
    const { type_of_person, type_of_organisation, service_ids, services } = this.state;

    if (type_of_person === "staff" && type_of_organisation === "Retail") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'services'} />

          <label className="column">Services:</label>{ renderErrors('services') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Add person to?</th>
              </tr>
              {services.map((service) => (
                <tr key={service.id}>
                  <td>
                    <Link to={`/configuration/services/${service.id}`}>{service.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={service.name} name={service.id} checked={service_ids.includes(service.id)} onChange={(event) => this.handleMultiCheckbox(event, "service_ids")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return(renderErrors('services'))
    }
  }

  renderTagsForm() {
    const { tag_ids, tags, errors } = this.state;

    if (localStorage.license === "base") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'tags'} />

          <label className="column">Tags:</label>{ renderErrors(errors, 'tag') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Assign tag</th>
              </tr>
              {tags.map((tag) => (
                <tr key={tag.id}>
                  <td>
                    <Link to={`/configuration/tags/${tag.id}`}>{tag.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={tag.name} name={tag.id} checked={tag_ids.includes(tag.id)} onChange={(event) => this.handleMultiCheckbox(event, "tag_ids")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }

  renderContractedHoursForm() {
    const { type_of_person, contracted_hours, errors } = this.state;

    if (type_of_person === "staff" || type_of_person === "contractor") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'contracted_hours'} />

          <label className="column">Weekly Contracted Hours:</label>{ renderErrors(errors, 'contracted_hours') }
          <input className="column" type="number" step="0.1" min="0" max="168" name="contracted_hours" value={contracted_hours} onChange={this.handleChange} />
        </div>
      )
    }
  }

  gridColumns(count) {
    var output = ""

    for (var i = 0; i < count; i++) {
      output += "1fr "
    }

    return output
  }

  render() {
    const { first_name, last_name, alternate_name, display_name, phone, email, type_of_person, covid_pass_exemption, exemption_date, editor_name, editor_id, person_custom_attributes, location_ids, locations, suggested_merge_exceptions, redlist, visit_all, picture, photo_opt_out, hideResidents, full_name, database_name, loaded, locationsLoaded, rolesLoaded, servicesLoaded, tagsLoaded, customAttributesLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      if (error["errors"]) {
        return (
          <div>
            {error["errors"].map((errors, index) => (
              <div className="warning-box" key={index}>{errors}</div>
            ))}
          </div>
        )
      }
      else {
        return <div className="warning-box">{error.message}</div>
      }
    }

    if (loaded === false || locationsLoaded === false || rolesLoaded === false || tagsLoaded === false || customAttributesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && locationsLoaded && rolesLoaded && servicesLoaded && tagsLoaded && customAttributesLoaded) {
      const baseLicense = localStorage.license === "base"

      let display_names = [first_name]

      if (alternate_name) {
        display_names = display_names.concat(alternate_name.split(","))
      }

      return (
        <div>
          <SetTitle title={`Edit Person | ${first_name} ${last_name} | People`} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            {this.renderPersonImported()}
            {this.renderImportWarnings()}
            {this.renderPersonImports()}
            <div className="two-columns">
              <div className="column">
                <div className="row">
                  <HelpText page={'person'} section={'firstName'} />

                  <label className="column">First Name:</label>{ renderErrors(errors, 'required') }
                  <input className="column" type="text" name="first_name" value={first_name} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'lastName'} />

                  <label className="column">Last Name:</label>{ renderErrors(errors, 'required') }
                  <input className="column" type="text" name="last_name" value={last_name} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'alternateName'} />

                  <label className="column">Alternate Name:</label>{ renderErrors(errors, 'required') }
                  <input className="column" type="text" name="alternate_name" value={alternate_name} onChange={this.handleChange} />
                </div>
              </div>
              <div className="column">
                {this.renderPersonPicture(picture)}
              </div>
            </div>

            <div className="row">
              <HelpText page={'person'} section={'displayName'} />

              <label className="column">Display Name</label>{ renderErrors(errors, 'display_name') }

              <div style={{ display: "grid",gridTemplateColumns: this.gridColumns(display_names.length + 1)}}>
                {display_names.map((display_name_option, index) => (
                  <div className="question-type" key={index}>
                    <input className="column" type="radio" name="display_name" id={index} value={display_name_option} checked={display_name === display_name_option} onChange={this.handleChange} />
                    <label htmlFor="boolean">{display_name_option}</label>
                  </div>
                ))}

                <div className="question-type">
                  <input className="column" type="radio" name="display_name" id="" value="" checked={display_name === ""} onChange={this.handleChange} />
                  <label htmlFor="text"><em>None</em></label>
                </div>
              </div>
            </div>

            <NoticeBox type="info">
              <HelpText page={'person'} section={'namePreview'} />

              <label className="column">Their name will display as: {renderFullName({first_name: first_name, alternate_name: alternate_name, display_name: display_name, last_name: last_name})}</label>{ renderErrors(errors, 'required') }
            </NoticeBox>

            <div className="row">
              <HelpText page={'person'} section={'phone'} />

              <label className="column">Phone:</label>{ renderErrors(errors, 'required') }
              <input className="column" type="text" name="phone" value={phone} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'person'} section={'email'} />

              <label className="column">Email:</label>{ renderErrors(errors, 'required') }
              <input className="column" type="email" name="email" value={email} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'person'} section={hideResidents ? 'type_without_residents' : 'type'} />

              <label className="column">Type:</label>{ renderErrors(errors, 'type_of_person') }
              <TypeOfPersonSelect value={type_of_person} onChange={this.handleChange} />
            </div>

            {baseLicense && (
              <>
                <button className="button" onClick={this.openModal}>Add Custom Attribute</button>
                {this.renderCustomAttributes(person_custom_attributes)}
              </>
            )}

            <div className="row">
              <HelpText page={'person'} section={'locations'} />

              <label className="column">Location:</label>{ renderErrors(errors, 'location') }
              { this.renderLocationMessage() }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Add person to?</th>
                  </tr>
                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" data-name={location.name} name={location.id} checked={location_ids.includes(location.id)} onChange={(event) => this.handleMultiCheckbox(event, "location_ids")} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {this.renderRolesForm()}
            {this.renderServicesForm()}
            {this.renderTagsForm()}
            {this.renderContractedHoursForm()}

            {baseLicense && (
              <>
                <div className="row">
                  <HelpText page={'person'} section={'covid_pass_expiry_date'} />

                  <label className="column">Covid vaccination pass expiry date:</label>
                  <label className="right-column">{this.covidPassExpiryDate()}</label>
                </div>
                <br/>
                <div className="row">
                  <HelpText page={'person'} section={'covid_pass_exemption'} />

                  <input className="column" type="checkbox" name="covid_pass_exemption" id="covid_pass_exemption_checkbox" checked={covid_pass_exemption} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="covid_pass_exemption_checkbox">Does not need to scan Covid vaccination QR?</label>{ renderErrors(errors, 'covid_pass_exemption') }
                </div>
                {this.renderAddCustomAttributeModal()}

                {this.renderExemptionDetails(exemption_date, editor_id, editor_name)}

                {this.renderSuggestedMergeExceptions(suggested_merge_exceptions)}

                <div className="row">
                  <HelpText page={'person'} section={'redlist'} />

                  <input className="column" type="checkbox" name="redlist" id="redlist_checkbox" checked={redlist} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="redlist_checkbox">Email warning upon arrival?</label>{ renderErrors(errors, 'redlist') }
                </div>

                {visit_all && <NoticeBox type="warning" text={`This person is set up to visit all ${capitalize(pluralize(visit_all))}. Whenever they arrive, they will be shown a list of every ${visit_all} at that Location. If they should not be able to see all ${pluralize(visit_all)} when they arrive, then you should remove their "Visit All" privileges.`} />}

                <div className="row">
                  <HelpText page={'person'} section={hideResidents ? 'visit_all_without_residents' : 'visit_all'} />

                  <label className="column">Allow {capitalize(type_of_person)} to Visit All:</label>{ renderErrors(errors, 'visit_all') }
                  <TypeOfPersonSelect name="visit_all" defaultText="None" value={visit_all} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'photo_opt_out'} />

                  <input className="column" type="checkbox" name="photo_opt_out" id="photo_opt_out_checkbox" checked={photo_opt_out} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="photo_opt_out_checkbox">Opt out of Photos?</label>{ renderErrors(errors, 'photo_opt_out') }
                </div>
              </>
            )}

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>
          { baseLicense &&
            <>
              <ListOfRelationships person_id={this.props.id} person_name={full_name} />
              <PersonEvents person_id={this.props.id} person_name={database_name} />
            </>
          }

          <Audits klass="Person" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          "first_name": data.first_name || "",
          "last_name": data.last_name || "",
          "alternate_name": data.alternate_name || "",
          "display_name": data.display_name || "",
          "phone": data.phone || "",
          "email": data.email || "",
          "type_of_person": data.type_of_person,
          "location_ids": data.location_ids,
          "role_ids": data.role_ids,
          "service_ids": data.service_ids,
          "tag_ids": data.tag_ids,
          "covid_pass_expiry_date": data.covid_pass_expiry_date,
          "covid_pass_exemption": data.covid_pass_exemption,
          "exemption_date": data.exemption_date,
          "editor_name": data.editor_name,
          "editor_id": data.editor_id,
          "person_custom_attributes": data.custom_attributes,
          "person_imports": data.person_imports,
          "suggested_merge_exceptions": data.suggested_merge_exceptions,
          "redlist": data.redlist,
          "contracted_hours": data.contracted_hours !== null ? data.contracted_hours : "",
          "visit_all": data.visit_all || "",
          "picture": data.picture,
          "photo_opt_out": data.photo_opt_out || false,
          "full_name": data.full_name,
          "database_name": renderFullName({
            first_name: data.first_name,
            last_name: data.last_name,
            display_name: data.display_name
          }),
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    if (localStorage.license === "base") {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ roles: data, rolesLoaded: true })
        })
        .catch(error => this.setState({ error, rolesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/services/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ services: data, servicesLoaded: true })
        })
        .catch(error => this.setState({ error, tagsLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ tags: data, tagsLoaded: true })
        })
        .catch(error => this.setState({ error, tagsLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/custom_attributes/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ custom_attributes: data, customAttributesLoaded: true })
        })
        .catch(error => this.setState({ error, customAttributesLoaded: true }))
    }
    else {
      this.setState({
        roles: [],
        tags: [],
        custom_attributes: [],
        rolesLoaded: true,
        tagsLoaded: true,
        customAttributesLoaded: true
      })
    }
  }
}

export default UpdatePerson;
