import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import { request, reportErrorToHoneybadger } from '../helpers/Requests'

import ProgressBar from "./components/ProgressBar"
import Header from "./components/Header"

import ServiceForm from "./pages/ServiceForm"
import StaffForm from "./pages/StaffForm"
import SlotForm from "./pages/SlotForm"
import ConfirmForm from "./pages/ConfirmForm"
import DepositForm from "./pages/DepositForm"

const BookingForm = () => {
  const { page_name, location_info, organisation_info } = useParams();
  const embedded = page_name === "embedded_book"

  const deposit = new URLSearchParams(window.location.search).get("deposit")

  const [page, setPage] = useState("service");
  const [location_id, setLocationId] = useState("")
  const [organisation_id, setOrganisationId] = useState("")
  // main request
  const [location_name, setLocationName] = useState(null)
  const [organisation_name, setOrganisationName] = useState(null)
  const [background_image, setBackgroundImage] = useState(null)
  const [bookingFormDisabled, setBookingFormDisabled] = useState(false);
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null);
  // sub pages
  const [service, setService] = useState(null);
  const [staff, setStaff] = useState(null);
  const [slot, setSlot] = useState(null);
  const [planned_event_id, setPlannedEventId] = useState(null);
  const [confirmation, setConfirmation] = useState(null)

  useEffect(() => {
    // organisation_info = "Fellowship-Care-101"
    // location_info = "Alexander-Fleming-House-333"
    const split_org_info = organisation_info.split("-")

    setOrganisationId(split_org_info[split_org_info.length - 1])
    setOrganisationName(split_org_info.slice(0, -1).join(" "))

    const split_location_info = location_info.split("-")
    setLocationId(split_location_info[split_location_info.length - 1])
    setLocationName(split_location_info.slice(0, -1).join(" "))
  }, [organisation_info, location_info]);

  useEffect(() => {
    if (deposit) {
      return
    }

    if (!organisation_id || !location_id) {
      return
    }

    request('GET', `/v1/b/location?booking[organisation_id]=${organisation_id}&booking[location_id]=${location_id}`)
      .then(data => {
        setLocationName(data.location_name)
        setOrganisationName(data.organisation_name)
        setBackgroundImage(data.background_image)
        setLoaded(true)

        document.querySelector(':root').style.setProperty('--page-border-color', data.border_color)
      })
      .catch(data => {
        reportErrorToHoneybadger(data.errors)

        setError(data.errors.join(", "))
        setBookingFormDisabled(true)
      })
  }, [deposit, organisation_id, location_id]);

  const handleReset = () => {
    setPage("service")
    setService(null)
    setSlot(null)
    setStaff(null)
    setConfirmation(null)
  }

  const renderPage = () => {
    switch(page) {
      case "service":
        return <ServiceForm setService={setService} setBookingFormDisabled={setBookingFormDisabled} setPage={setPage} organisation_id={organisation_id} location_id={location_id} />
      case "staff":
        return <StaffForm setStaff={setStaff} setPage={setPage} organisation_id={organisation_id} location_id={location_id} service_id={service.id} />
      case "slot":
        return <SlotForm setSlot={setSlot} setPage={setPage} organisation_id={organisation_id} location_id={location_id} service_id={service.id} staff_id={staff.id} />
      case "confirm":
        return <ConfirmForm setConfirmation={setConfirmation} setPlannedEventId={setPlannedEventId} setPage={setPage} organisation_id={organisation_id} location_id={location_id} service_id={service.id} staff_id={staff.id} start_time={slot.start_time} />
      case "deposit":
        return <DepositForm organisation_id={organisation_id} location_id={location_id} service_id={service.id} planned_event_id={planned_event_id} />
      default:
        return null
    }
  }

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  if (deposit) {
    return (
      <div className="background-image" style={{ backgroundImage: `url(${background_image})` }}>
        <div className="booking-container">
          <div className="booking-form">
            <h1>Thank you</h1>

            <p>Your slot has been booked and your deposit has been paid. Look out for an email for further details.</p>

            <button className="button no-margin" onClick={() => {window.location.search = ""}}>Make another booking</button>
          </div>
        </div>
      </div>
    )
  }

  if (loaded === false) {
    return (
      <div className="background-image" style={{ backgroundImage: `url(${background_image})` }}>
        <div className="booking-container">
          <div className="booking-form">
            {embedded === false ? <Header organisation_name={organisation_name} location_name={location_name} /> : null}

            Loading...
          </div>
        </div>
      </div>
    )
  }

  if (confirmation) {
    return (
      <div className="background-image" style={{ backgroundImage: `url(${background_image})` }}>
        <div className="booking-container">
          <div className="booking-form">
            {embedded === false ? <Header organisation_name={organisation_name} location_name={location_name} /> : null}

            <h3>Booking Confirmation</h3>

            <div className="confirmed-form">
              <div className="bottom-margin">Your booking has been placed under the name <strong>{confirmation.arriving_person}</strong></div>

              <div className="booking-progress bottom-margin">
                <div className="progress-section">Location: <strong>{confirmation.location}</strong></div>
                <div className="progress-section">Start Time: <strong>{confirmation.start_time}</strong></div>
                <div className="progress-section">End Time: <strong>{confirmation.end_time}</strong></div>
                <div className="progress-section">Service: <strong>{confirmation.service}</strong></div>
                <div className="progress-section">Staff: <strong>{confirmation.host_person}</strong></div>
              </div>

              <button className="button" onClick={handleReset}>Make another booking</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="background-image" style={{ backgroundImage: `url(${background_image})` }}>
      <div className="booking-container">
        <div className="booking-form">
          {embedded === false && bookingFormDisabled === false ? <Header organisation_name={organisation_name} location_name={location_name} /> : null}
          <ProgressBar page={page} service={service} staff={staff} slot={slot} setPage={setPage} />

          {renderPage()}
        </div>
      </div>
    </div>
  )
}

export default BookingForm;
