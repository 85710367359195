import React, { useState, useEffect } from "react";

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

const ServiceForm = ({ setService, setBookingFormDisabled, setPage, organisation_id, location_id }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    request('GET', `/v1/b/services?booking[organisation_id]=${organisation_id}&booking[location_id]=${location_id}`)
      .then(data => {
        setServices(data)
        setLoaded(true)
      })
      .catch(data => {
        reportErrorToHoneybadger(data.errors)

        setError(data.errors.join(", "))
        setBookingFormDisabled(true)
      })
  }, [organisation_id, location_id, setBookingFormDisabled]);

  const handleService = (event) => {
    const id = parseInt(event.target.id)
    const service = services.find(service => service.id === id);

    setService(service)
    setPage("staff")
  }

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  if (loaded === false) {
    return (
      <div className="service-selection">
        <p>Loading ...</p>
      </div>
    )
  }

  if (loaded === true) {
    return (
      <>
        <h3>Service Selection</h3>
        <div className="service-form">
          {services.map((service) => (
            <div className="selection" key={service.id} id={service.id} onClick={handleService}>
              {service.name} - {service.default_price}
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default ServiceForm;
