import React, { useState, useEffect } from "react";

import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

const SlotForm = ({ setSlot, setPage, organisation_id, location_id, service_id, staff_id }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [date, setDate] = useState(new Date());
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    request('GET', `/v1/b/slot?booking[organisation_id]=${organisation_id}&booking[location_id]=${location_id}&booking[service_id]=${service_id}&booking[staff_id]=${staff_id}&booking[start_date]=${date}`)
      .then(data => {
        setSlots(data)
        setLoaded(true)
      })
      .catch(data => {
        reportErrorToHoneybadger(data.errors)

        setError(data.errors.join(", "))
      })
  }, [organisation_id, location_id, service_id, staff_id, date]);

  const handleSlot = (event) => {
    const index = parseInt(event.target.id)
    const slot = slots[index]

    setSlot(slot)
    setPage("confirm")
  }

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  return (
    <div className="slot-form">
      <div className="slot-calendar">
        <Calendar onChange={setDate} value={date} />
      </div>
      <div className="slot-selection">
        <h3 className="margin-left margin-right">Available time slots</h3>
        <div className="slot-scroller">
          {loaded === false ? (
            <p>Loading...</p>
          ) : (
            slots.length === 0 ? (
              <>
                <p>No timeslots available for that day.</p>
                <p>Please select another day, or choose a different staff member.</p>
              </>
            ) : (
              slots.map((slot, index) => (
                <div className="selection" key={index} id={index} onClick={handleSlot}>
                  {slot.time}
                </div>
              ))
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default SlotForm;
