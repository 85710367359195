import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import NoticeBox from '../../components/shared/NoticeBox';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrors } from '../../utilities/Forms.js'

class UpdateWorkingPattern extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    rota_start_date: (this.props.editRole ? this.props.editRole.rota_start_date : this.props.location_rota_start_date) || "",
    default_period_weeks: (this.props.editRole ? this.props.editRole.default_period_weeks : this.props.location_default_period_weeks) || "",
    use_previous_configuration_until_new_start_date: false,

    minDate: this.calculateMinDate(),

    updating: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  calculateMinDate() {
    const date = new Date();
    date.setDate(date.getDate() + (8 - date.getDay()))

    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    if (value === "true") {
      value = true
    }

    if (value === "false") {
      value = false
    }

    this.setState({
      [name]: value,
      showNotice: true
    });
  }

  handleSubmit(event) {
    this.setState({ updating: true })
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "staff_fulfilment": {
        "rota_start_date": this.state.rota_start_date,
        "default_period_weeks": this.state.default_period_weeks,
        "use_previous_configuration_until_new_start_date": this.state.use_previous_configuration_until_new_start_date
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    const urlEnding = this.props.editRole ? `configure_role_rota?role_id=${this.props.editRole.id}` : `configure_location_rota?location_id=${this.props.location_id}`

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/staff_fulfilment/${urlEnding}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data, updating: false })
      } else {
        this.setState({ errors: "", updating: false })
        sessionStorage.setItem("updateSuccess", `${this.props.editRole ? "Role" : "Location"} updated successfully!`);
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderSaveButton() {
    if (adminUser()) {
      return (
        <button className="button" disabled={this.state.updating} onClick={this.handleSubmit}>
          Save
          <LoadingSpinner condition={this.state.updating} />
        </button>
      )
    }
    else {
      return <button disabled="disabled" className="button">Sorry, you don't have permission to save changes</button>
    }
  }

  render() {
    const { rota_start_date, default_period_weeks, use_previous_configuration_until_new_start_date, showNotice, unauthorized, error, errors } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    let notice = []

    if (default_period_weeks) {
      notice.push(`This sets up the working pattern on a rolling ${default_period_weeks} week period.`)
    }

    if (rota_start_date) {
      let bullet = `This working pattern will start on ${rota_start_date}`

      if (default_period_weeks > 1) {
        bullet += " with Week 1 starting as that week"
      }

      notice.push(bullet)
    }

    const props_default_period_weeks = (this.props.editRole ? this.props.editRole.default_period_weeks : this.props.location_default_period_weeks) || 1

    if (parseInt(default_period_weeks) < props_default_period_weeks) {
      notice.push(`Any default records that do not lie entirely inside the first ${parseInt(default_period_weeks) > 1 ? `${default_period_weeks} weeks` : "week"} will be deleted.`)
    }

    if (use_previous_configuration_until_new_start_date) {
      notice.push("Any pre-existing default associated weekly records up intil this date are set to be kept.")

      if (rota_start_date) {
        // Any date after the 3rd next Sunday from now will require additional instantiation
        let requiresAdditionalInstantiation = new Date();
        requiresAdditionalInstantiation.setDate(requiresAdditionalInstantiation.getDate() - requiresAdditionalInstantiation.getDay() + 28);
        let comparisonDate = new Date(rota_start_date);

        if (comparisonDate > requiresAdditionalInstantiation) {
          notice.push("We will also instantiate your current template rota from 4 weeks from now up until this new rota start date.")
        }
      }

      notice.push("Leftover default associated weekly records from your previous working pattern will be dissociated with your defaults so that you can modify your template in preparation for the beginning of your new working pattern.")
    }
    else {
      notice.push("Any pre-existing default associated weekly records that have not already started are set to be deleted.")
    }

    const initialInfo = this.props.editRole ? "This configuration is for the current Role. Any default records associated with this Role will use this configuration, no matter what Location they may be scheduled at." : "This configuration is for the current Location. Any default records associated with this Location will use this configuration unless their specific Role also has its own unique configuration."

    return (
      <div>
        <SetTitle title={`Edit Working Pattern | ${this.props.editRole ? "Role" : "Location"} #${this.props.editRole ? this.props.editRole.id : this.props.location_id} | Staff Fulfilment`} />

        <form className="settings big-settings update-working-pattern">
          <NoticeBox type="info" text={initialInfo} />
          <div className="row">
            <HelpText page={'working_pattern'} section={'rota_start_date'} />

            <div className="divided-columns">
              <label className="column">Rota Start Date:</label>{ renderErrors(errors, 'rota_start_date') }
              <input className="column" name="rota_start_date" type="date" min={this.state.minDate} value={rota_start_date} onChange={this.handleChange} />
            </div>
          </div>

          <div className="row">
            <HelpText page={'working_pattern'} section={'default_period_weeks'} />

            <div className="divided-columns">
              <label className="column">Default Period Weeks:</label>{ renderErrors(errors, 'default_period_weeks') }
              <input className="column" name="default_period_weeks" type="number" min="1" max="4" value={default_period_weeks} onChange={this.handleChange} />
            </div>
          </div>

          <div className="row">
            <HelpText page={'working_pattern'} section={'use_previous_configuration_until_new_start_date'} />
            <div className="divided-columns">
              <label htmlFor="true">Keep existing data</label>
              <input className="column" type="checkbox" name="use_previous_configuration_until_new_start_date" id="true" value="true" checked={use_previous_configuration_until_new_start_date === true} onChange={this.handleChange} />

              <label htmlFor="false">Delete existing data</label>
              <input className="column" type="checkbox" name="use_previous_configuration_until_new_start_date" id="stars" value="false" checked={use_previous_configuration_until_new_start_date === false} onChange={this.handleChange} />
            </div>
          </div>

          {showNotice && notice &&
            <NoticeBox type="info">
              <ul>
                {notice.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
            </NoticeBox>
          }

          { this.renderSaveButton() }
        </form>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.editRole) {
      this.setState({
        default_period_weeks: this.props.editRole.default_period_weeks || "",
        rota_start_date: this.props.editRole.rota_start_date || ""
      })
    }

    else {
      this.setState({
        default_period_weeks: this.props.location_default_period_weeks || "",
        rota_start_date: this.props.location_rota_start_date || ""
      })
    }
  }
}

export default UpdateWorkingPattern;
