import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import Page from '../../components/shared/Page';
import { convertTime, getContrastYIQ } from '../../utilities/Generic.js'
import CreateDefaultRequiredAllocation from '../../components/staff-fulfilment/CreateDefaultRequiredAllocation';
import UpdateDefaultRequiredAllocation from '../../components/staff-fulfilment/UpdateDefaultRequiredAllocation';
import Flow from '../../components/staff-fulfilment/Flow';
import StaffFulfilmentConfigurationIndex from '../../components/staff-fulfilment/StaffFulfilmentConfigurationIndex';
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'

import { adminUser, managerUser } from '../../utilities/Forms.js'

import SlidingPane from "../../components/shared/ScrollableSlidingPane";

class StaffFulfilmentDefaultRequiredAllocation extends Component {
  constructor(props) {
    super(props);

    this.handleNewPanel = this.handleNewPanel.bind(this);
    this.handleEditPanel = this.handleEditPanel.bind(this);
    this.closeNewPanel = this.closeNewPanel.bind(this);
    this.closeEditPanel = this.closeEditPanel.bind(this);
  }

  state = {
    // location settings or org settings?
    workStartsAt: 0,
    durationInHours: 24,

    editId: null,
    clickedRole: null,
    clickedDayOfWeek: null,
    clickedHour: null,
    newPanelToggle: false,
    editPanelToggle: false,

    rotaLoaded: false,
    rolesLoaded: false,
    allocationsLoaded: false,

    roles: null,
    allocations: null
  }

  handleNewPanel(event) {
    if (adminUser === false && managerUser === false) {
      return
    }

    const clickedRole = event.currentTarget.dataset.role_id;
    const clickedDayOfWeek = event.currentTarget.dataset.day_of_week
    let clickedHour = event.currentTarget.dataset.start_hour

    this.setState({
      newPanelToggle: true,
      clickedRole: clickedRole,
      clickedDayOfWeek: clickedDayOfWeek,
      clickedHour: clickedHour,
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/template/new`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/template/new`)
    }
  }

  handleEditPanel(event) {
    var id = event.currentTarget.id;

    this.setState({
      editId: id,
      editPanelToggle: true
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/template/${id}`)
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/template/${id}`)
    }

    event.stopPropagation();
  }

  closeNewPanel() {
    this.setState({
      newPanelToggle: false,
      clickedRole: null,
      clickedDayOfWeek: null,
      clickedHour: null,
      flowDate: new Date().valueOf()
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/template`);
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/template`);
    }

    this.componentDidMount();
  }

  closeEditPanel() {
    this.setState({
      editPanelToggle: false,
      editId: null,
      flowDate: new Date().valueOf()
    });

    if (this.props.match.params.rota_id !== undefined) {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/rotas/${this.props.match.params.rota_id}/required-allocation/template`);
    }
    else {
      this.props.history.push(`/staff-fulfilment/${this.props.match.params.location_id}/required-allocation/template`);
    }

    this.componentDidMount();
  }

  renderRotaName() {
    return this.state.rota === undefined ? "" : ` - ${this.state.rota.name} Rota`
  }

  render() {
    const { match: { params } } = this.props;
    const { workStartsAt, durationInHours, clickedDayOfWeek, clickedRole, clickedHour, editId, roles, rota, rolesLoaded, rotaLoaded, allocationsLoaded, flowDate, unauthorized, error } = this.state;

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (!rolesLoaded || !allocationsLoaded || !rotaLoaded) {
      return <p>Loading ...</p>;
    }

    if (roles.length === 0) {
      return (
      <Page title={`Staff Fulfilment ${this.renderRotaName()}`}>
        {rota === undefined ?
          <Link to="/configuration/roles/new">Before using Staff Fulfilment, create a Role and assign some Staff to it.</Link>
          :
          <Link to={`/rota-types/${rota.id}`}>Please add some Roles to this Rota Type before using it.</Link>
        }
      </Page>
      )
    }

    return (
      <div className="fulfilment">
        <Page title={`Staff Fulfilment ${this.renderRotaName()}`}>

          <Flow location_id={params.location_id} rota_id={params.rota_id} page={"Required Allocation Template"} date={flowDate} />

          <div>
            {this.renderDailyRotas()}
          </div>
        </Page>

        <ModalContainerTrigger triggerText="Template configuration" className="small button right config">
          <StaffFulfilmentConfigurationIndex location_id={params.location_id} />
        </ModalContainerTrigger>

        <SlidingPane isOpen={this.state.newPanelToggle} title="New Default Required Allocation" width="60%" onRequestClose={this.closeNewPanel}>
          <CreateDefaultRequiredAllocation closeNewPanel={this.closeNewPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} clickedHour={clickedHour} day_of_week={clickedDayOfWeek} role_id={clickedRole} location_id={params.location_id} rota_id={params.rota_id} />
        </SlidingPane>

        <SlidingPane isOpen={this.state.editPanelToggle} title="Edit Default Required Allocation" width="60%" onRequestClose={this.closeEditPanel}>
          <UpdateDefaultRequiredAllocation id={editId} closeEditPanel={this.closeEditPanel} workStartsAt={workStartsAt} dayDuration={durationInHours} location_id={params.location_id} rota_id={params.rota_id} />
        </SlidingPane>

      </div>
    );

  }

  renderDailyRotas() {
    let dates = []
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    const maxDefaultPeriodWeeks = Math.max(...Object.values(this.state.allocations.role_data).map(role => role.default_period_weeks || 1));

    for (let i = 0; i < 7 * maxDefaultPeriodWeeks; i++) {
      const date = new Date(tomorrow);
      date.setDate(tomorrow.getDate() + i);

      dates.push(date)
    }

    return (
      <div className="rotas">
        {dates.map((date) => (
          this.renderIndividualDay(date)
        ))}
      </div>
    )
  }

  renderIndividualDay(date) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = daysOfWeek[date.getDay()]

    const { workStartsAt, durationInHours } = this.state;
    const workingHours = Array.from({length: durationInHours}, (_, i) => workStartsAt + i);

    const roles = this.state.roles;

    const jsDayOfWeek = date.getDay()
    const day_of_week = jsDayOfWeek === 0 ? 6 : jsDayOfWeek - 1;

    return (
      <div key={date} className="container">
        <div className="graph">

          {/* Header */}
          <div className="roles">
            <div className="left">
              <div className="role-label">Roles</div>
              <div className="time-label">Time</div>
            </div>
            <div className="right">
              <div className="title">{day}</div>
              <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
                {workingHours.map((hour, index) => (
                  <div key={hour} className="hour-title">{hour}</div>
                ))}
              </div>
            </div>
          </div>

          {roles.map(role => (
            this.renderIndividualRole(role, workingHours, date, day_of_week)
          ))}
        </div>
      </div>
    )
  }

  renderIndividualRole(role, workingHours, date, day_of_week) {
    const today = new Date(date)
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)

    // If this Role isn't in the Allocation list, it shouldn't be rendered.
    // Otherwise we wouldn't be hiding the Roles not selected in the RotaType.
    if (this.state.allocations.role_data[role.id] === undefined) {
      return
    }

    const start_of_current_period = new Date(this.state.allocations.role_data[role.id].start_of_current_period)

    const default_period = this.state.allocations.role_data[role.id].default_period_weeks * 7

    // Convert millisecond difference into days (86400000 === 1000 * 60 * 60 * 24))
    const currentDay = (Math.round((today - start_of_current_period) / 86400000)) % default_period

    const padding = 0

    if (this.state.allocations.rota === undefined || this.state.allocations.rota[currentDay] === undefined || this.state.allocations.rota[currentDay][role.id] === undefined) {
      return (
        <div className="roles" key={`role${role.id}`}>
          <div className="left">
            <div className="row-labels" style={{gridTemplateRows: `repeat(${1}, 1fr)`}}>
              {Array.from({ length: 1 + padding}).map((_, index) => (
                <div key={index}>{1 - index + padding}</div>
              ))}
            </div>
            <div className="role-title">
              <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
              <div className="hour-count">Week {Math.floor(currentDay / 7) + 1} of {this.state.allocations.role_data[role.id].default_period_weeks}</div>
              <div className="hour-count">0 hours required</div>
            </div>
          </div>
          <div className="right">
            <div className="assigned-hours">
            </div>
            <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
              {workingHours.map(hour => (
                <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={currentDay}><wbr/></div>
              ))}
            </div>
          </div>
          <div className="new-shift">
            <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={currentDay} data-role_id={role.id}>+</div>
          </div>
        </div>
      )
    }

    const data = this.state.allocations.rota[currentDay][role.id]
    const height = this.gridRowHeight(data)
    const hoursRequired = this.calculateTotalHoursRequired(data)

    return (
      <div className="roles" key={`role${role.id}`}>
        <div className="left">
          <div className="row-labels" style={{gridTemplateRows: `repeat(${height}, 1fr)`}}>
            {Array.from({ length: height }).map((_, subindex) => (
              <div key={subindex}>{height - subindex}</div>
            ))}
          </div>
          <div className="role-title">
            <div className="bubble" style={{ backgroundColor: role.color, color: getContrastYIQ(role.color)}}>{role.name}</div>
            <div className="hour-count">Week {Math.floor(currentDay / 7) + 1} of {this.state.allocations.role_data[role.id].default_period_weeks}</div>
            <div className="hour-count">{hoursRequired} hours required</div>
          </div>
        </div>
        <div className="right">
          <div className="assigned-hours">
            {this.renderRotaHours(data, role.id, currentDay)}
          </div>
          <div className="hours" style={{gridTemplateColumns: `repeat(${workingHours.length}, 1fr)`}}>
            {workingHours.map(hour => (
              <div className="hour-column" key={hour} onClick={this.handleNewPanel} data-start_hour={`0${hour}:00`.slice(-5)} data-role_id={role.id} data-day_of_week={day_of_week}><wbr/></div>
            ))}
          </div>
        </div>
        <div className="new-shift">
          <div className="plus-button" onClick={this.handleNewPanel} data-day_of_week={currentDay} data-role_id={role.id}>+</div>
        </div>
      </div>
    )
  }

  gridRowHeight(data) {
    const defaultHeight = 5;
    let height;

    if (data && data.length > 0) {
      height = Math.max(...data.map(allocation => allocation.number_of_staff))
    }

    return height || defaultHeight
  }

  calculateTotalHoursRequired(data) {
    let totalHours = 0

    data.forEach((record, index) => {
      const height = record.number_of_staff

      const [startHours, startMinutes] = record.start_time.split(":").map(Number);
      const [endHours, endMinutes] = record.end_time.split(":").map(Number);

      const startMinute = startHours * 60 + startMinutes;
      let endMinute = endHours * 60 + endMinutes;

      if (endMinute > 1440) {
        endMinute = 1440
      }

      const numberOfHours = (endMinute - startMinute) / 60

      totalHours += numberOfHours * height
    })

    return Math.ceil(totalHours)
  }

  renderRotaHours(data, role_id) {
    let highestPoint = 0;

    data.forEach(record => {
      if (record.number_of_staff > highestPoint) {
        highestPoint = record.number_of_staff;
      }
    });

    return (
      data.map((record, index) => {
        const [startHours, startMinutes] = record.start_time.split(":").map(Number);
        const [endHours, endMinutes] = record.end_time.split(":").map(Number);

        const startMinute = startHours * 60 + startMinutes;
        let endMinute = endHours * 60 + endMinutes;
        const height = record.number_of_staff

        if (endMinute > 1440) {
          endMinute = 1440
        }

        return (
          <React.Fragment key={index}>
            <div className="allocated-shift" id={record.id} style={{clipPath: `polygon(${this.calculateCoordinates(startMinute, endMinute, height, highestPoint)})`}} onClick={this.handleEditPanel}>
              <div className="number-of-people" style={this.calculateOffset(startMinute, height, highestPoint)}>{record.number_of_staff}</div>
              <div className="time" style={this.calculateOffset(startMinute, height, highestPoint, true)}>{this.shiftTime(record)}</div>
            </div>
          </React.Fragment>
        )
      })
    )
  }

  calculateCoordinates(startMinute, endMinute, height, maxHeight) {
    if (!(height > 0)) {
      return "0% 0%"
    }

    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const startPercentage = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const endPercentage = ((endMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;

    const blockHeight = 100 - (height / maxHeight) * 100
    const bottomOfGraph = 100

    let points = []

    points.push(`${startPercentage}% ${bottomOfGraph}%`)
    points.push(`${startPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${blockHeight}%`)
    points.push(`${endPercentage}% ${bottomOfGraph}%`)

    // Important note for debugging:
    //
    // The CSS attribute clip-path that we use to drive this feature has a wonky axis.
    //
    // If the calculations seem surprising, this is why:
    //
    //      0%                   100%
    //   0% ┌───────────────────────┐ 0%
    //      │                       │
    //      │                       │
    //    Y │                       │
    //    - │                       │
    //    a │                       │
    //    x │                       │
    //    i │                       │
    //    s │                       │
    //      │                       │
    //      │                       │
    // 100% └───────────────────────┘ 100%
    //      0%        X-axis      100%
    //

    return points
  }

  calculateOffset(startMinute, height, maxHeight, timeLabel = false) {
    if (!(startMinute > 0)) {
      return { left: "0%", right: "0%" }
    }

    const leftPadding = 1
    let topPadding = 1

    if (timeLabel) {
      topPadding += 33
    }

    const graphStartRange = this.state.workStartsAt * 60
    const graphEndRange = graphStartRange + (this.state.durationInHours * 60)

    const leftOffset = ((startMinute - graphStartRange) / (graphEndRange - graphStartRange)) * 100;
    const blockHeight = 100 - (height / maxHeight) * 100

    return {
      left: `${leftOffset + leftPadding}%`,
      top: `${blockHeight + topPadding}%`
    }
  }

  shiftTime(datum) {
    let startTime = parseInt(datum.start_time)
    let endTime = parseInt(datum.end_time)

    return `${convertTime(startTime, false)} - ${convertTime(endTime)}`
  }

  static getDerivedStateFromProps(props, state) {
    const id = props.match.params.id
    if (state.newPanelToggle && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else if (state.editPanelToggle && id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && id !== state.editId) {
      return {
        editId: id
      }
    }
    else if (state.editPanelToggle === false && id !== undefined) {
      return {
        editPanelToggle: true,
        editId: id
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && (props.history.location.pathname.endsWith("/required-allocation") || props.history.location.pathname.endsWith("/required-allocation/template"))) {
      return {
        newPanelToggle: false,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && props.history.location.pathname.endsWith("/new")) {
      return {
        newPanelToggle: true,
        editPanelToggle: false,
        editId: null
      }
    }
    else if (props.history.action === "POP" && id !== undefined) {
      return {
        newPanelToggle: false,
        editPanelToggle: true,
        editId: id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };


    const location_id = this.props.match.params.location_id
    const rota_id = this.props.match.params.rota_id

    let queryString = `location_id=${location_id}`

    if (rota_id !== undefined) {
      queryString += `&rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/default_required_allocations?${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ allocations: data, allocationsLoaded: true })
      })
      .catch(error => this.setState({ error, allocationsLoaded: true }))


    queryString = ""

    if (rota_id !== undefined) {
      queryString = `?rota_id=${rota_id}`
    }

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids${queryString}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    if (rota_id !== undefined) {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types/${rota_id}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ rota: data, rotaLoaded: true })
        })
        .catch(error => this.setState({ error, rotaLoaded: true }))
    }
    else {
      this.setState({ rotaLoaded: true })
    }
  }
}

export default StaffFulfilmentDefaultRequiredAllocation;
