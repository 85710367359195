import React, { useState, useEffect } from "react";

import { request, reportErrorToHoneybadger } from '../../helpers/Requests'

const StaffForm = ({ setStaff, setPage, organisation_id, location_id, service_id }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [staff, setStaffs] = useState([]);

  // TODO: Set up APIs

  useEffect(() => {
    request('GET', `/v1/b/staff?booking[organisation_id]=${organisation_id}&booking[location_id]=${location_id}&booking[service_id]=${service_id}`)
      .then(data => {
        setStaffs(data)
        setLoaded(true)
      })
      .catch(data => {
        reportErrorToHoneybadger(data.errors)

        setError(data.errors.join(", "))
      })
  }, [organisation_id, location_id, service_id]);

  const handleStaff = (event) => {
    const id = parseInt(event.target.id)
    const selectedStaff = staff.find(a_staff => a_staff.id === id);

    setStaff(selectedStaff)
    setPage("slot")
  }

  if (error) {
    return (
      <div class="booking-container">
        <div class="error">{error}</div>
      </div>
    )
  }

  if (loaded === false) {
    return (
      <div className="staff-selection">
        <p>Loading ...</p>
      </div>
    )
  }

  if (loaded === true) {
    return (
      <>
        <h3>Staff Selection</h3>
        <div className="staff-form">
          {staff.map((a_staff) => (
            <div className="selection" key={a_staff.id} id={a_staff.id} onClick={handleStaff}>
              {a_staff.name}
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default StaffForm;
